import gql from "graphql-tag";

export const NEXT_TRIP_QUERY = gql`
  query equipmentShipment($shipmentId: Int!) {
    equipmentShipment(id: $shipmentId) {
      id
      programmedStartTime
    }
  }
`;

export const TOTAL_VOLUME_QUERY = gql`
  query equipmentShipment($shipmentId: Int!) {
    equipmentShipment(id: $shipmentId) {
      id
      serviceOrder {
        id
        serviceRequestOrder {
          id
          quantity
        }
      }
    }
  }
`;
