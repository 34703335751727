import React from "react";
import {
  Card,
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  Button
} from "reactstrap";
import styled from "styled-components";
import { compose, graphql } from "react-apollo";
import { format } from "date-fns";
import {
  H3,
  InfoParagraph,
} from "components/StyledComponents";
import AlertModal from "components/AlertModal/AlertModal";
import TeamInfo from "components/TeamInfo/TeamInfo";
import { withQuery } from "containers/withQuery";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import ConciliateCard from "./ConciliateCard";
import { PENDING_SHIPMENTS, CONCILIATE_SHIPMENTS_MUTATION } from "./conciliateShipmentsGraphql";

const StyledContainer = styled(Container)`
  max-height: 35vh;
  overflow: scroll;
`;

const StyledCardHeader = styled(CardHeader)`
  padding-bottom: 10px !important;
  border-bottom: 2px solid #F4F5FC !important;
`;

const MATCHING_RESULT = 0;
const ANY_RESULT = 100;

const evaluate = (equipments, concreteShipment) => equipments.map(equipment => {
  const hasShipment = concreteShipment && concreteShipment.id;
  const sameFormula = hasShipment && concreteShipment.concreteFormula.code === equipment.serviceOrder.serviceRequestOrder.service.code;

  const rank = sameFormula ? MATCHING_RESULT : ANY_RESULT;

  return {
    ...equipment,
    rank,
  };
});

class ConciliateShipments extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      concreteShipment: null,
      equipmentShipment: null,
      teamSelected: [],
      operatorsSelected: [],
      rankedEquipments: evaluate(props.equipmentShipments),
    };

    this.state = this.initialState;
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      rankedEquipments: evaluate(props.equipmentShipments, state.concreteShipment),
    };
  }

  setClearCallback = callback => {
    this.onClear = callback;
  }

  selectConcreteShipment = value => () => this.setState(state => {
    const { equipmentShipments } = this.props;
    const selected = state.concreteShipment;

    if (selected && selected.id === value.id) {
      return this.initialState;
    }

    const rankedEquipments = evaluate(equipmentShipments, value);
    const matchingResults = rankedEquipments.filter(equipment => equipment.rank === MATCHING_RESULT).length;
    const noMatchingResult = matchingResults === 0;
    const snackbarColor = noMatchingResult ? Color.warning : Color.info;
    const snackbarMessage = noMatchingResult
      ? "Não foram encotradas ordens de serviço com o mesmo traço desta carga."
      : "Ok, escolha uma OS para continuar";

    this.props.snackbarContext.setNotificationTimeOut(snackbarColor, snackbarMessage, 2);

    return {
      equipmentShipment: null,
      concreteShipment: value,
      rankedEquipments,
    };
  });

  selectEquipmentShipment = value => () => this.setState(state => {
    const selected = state.equipmentShipment;

    const sameRank = value.rank === MATCHING_RESULT;
    const isSelected = selected && selected.id === value.id;
    const equipmentShipment = sameRank && isSelected ? null : value;

    return { ...sameRank && { equipmentShipment } };
  });

  onOperatorChange = (key, operators) => this.setState({ [key]: operators });

  onTeamChange = teamSelected => this.setState({ teamSelected });

  onConciliateError = () => {
    AlertModal.fire({
      title: "Ops!",
      html: (
        <React.Fragment>
          <InfoParagraph>
            {"Para conciliar você precisa seguir os seguintes passsos:"}
          </InfoParagraph>
          <InfoParagraph>
            {"1 - Escolher uma 'Carga não conciliada'"}
          </InfoParagraph>
          <InfoParagraph>
            {" 2 - Escolher uma 'Ordem de serviço pendente'"}
          </InfoParagraph>
          <InfoParagraph>
            {"3 - Definir 'Operador' e 'Equipe'"}
          </InfoParagraph>
        </React.Fragment>
      ),
      type: "warning",
      showCancelButton: false,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  }

  onConciliate = () => {
    const {
      concreteShipment,
      equipmentShipment,
      operatorsSelected,
      teamSelected,
    } = this.state;

    const operatorIds = [
      ...operatorsSelected.map(operator => operator.id),
      ...teamSelected.map(operator => operator.id),
    ];

    this.props.conciliateShipments({
      variables: {
        concreteShipmentId: concreteShipment.id,
        equipmentShipmentId: equipmentShipment.id,
        operatorIds,
      },
      refetchQueries: [
        { query: PENDING_SHIPMENTS },
      ]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Conciliamento realizado com sucesso!");
        this.onClear();
        this.setState(this.initialState);
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  conciliate = () => {
    const {
      concreteShipment,
      equipmentShipment,
      operatorsSelected,
    } = this.state;

    if (concreteShipment && equipmentShipment && operatorsSelected.length) {
      this.onConciliate();
    } else {
      this.onConciliateError();
    }
  }

  sortEquipments = ({ rank: a }, { rank: b }) => parseInt(a, 10) - parseInt(b, 10);

  renderConcreteShipmentItem = item => {
    const quantity = parseFloat(item.quantity).toFixed(1);
    const { concreteShipment } = this.state;

    return (
      <Col md={6} key={`concrete-${item.id}`}>
        <ConciliateCard
          quantity={`${quantity} m³`}
          formulaCode={item.concreteFormula.code}
          startTime={format(item.loadingStartTime, "HH:mm")}
          identification={`LC ${item.sealNumber}`}
          onClick={this.selectConcreteShipment(item)}
          selected={concreteShipment && concreteShipment.id === item.id}
          message={item.message}
        />
      </Col>
    );
  }

  renderEquipmentShipmentItem = item => {
    const { equipmentShipment } = this.state;

    return (
      <Col md={6} key={`equipment-${item.id}`}>
        <ConciliateCard
          quantity={`${item.equipmentRequestOrder.quantity} m³`}
          formulaCode={item.serviceOrder.serviceRequestOrder.service.code}
          startTime={item.programmedStartTime.slice(0, 5)}
          identification={`OS ${item.serviceOrder.code}`}
          additionalInfo={item.equipment.code}
          onClick={this.selectEquipmentShipment(item)}
          selected={equipmentShipment && equipmentShipment.id === item.id}
          disabled={item.rank === ANY_RESULT}
        />
      </Col>
    );
  }

  render() {
    const { concreteShipments } = this.props;
    const { rankedEquipments } = this.state;
    const sortedEquipments = [...rankedEquipments].sort(this.sortEquipments);

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col xl={8}>
              <Card>
                <StyledCardHeader>
                  <H3>Cargas não conciliadas</H3>
                </StyledCardHeader>

                <CardBody>
                  <StyledContainer fluid>
                    <Row>
                      {concreteShipments.map(this.renderConcreteShipmentItem)}
                    </Row>
                  </StyledContainer>
                </CardBody>
              </Card>

              <Card>
                <StyledCardHeader>
                  <H3>Ordens de serviço pendentes</H3>
                </StyledCardHeader>

                <CardBody>
                  <StyledContainer fluid>
                    <Row>
                      {sortedEquipments.map(this.renderEquipmentShipmentItem)}
                    </Row>
                  </StyledContainer>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <TeamInfo
                    onOperatorChange={this.onOperatorChange}
                    onTeamChange={this.onTeamChange}
                    setClearCallback={this.setClearCallback}
                  />
                </CardBody>
              </Card>

              <Button color="info" onClick={this.conciliate} className="btn-block">
                CONCILIAR
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default compose(
  withSnackbarConsumer,
  withQuery({
    query: PENDING_SHIPMENTS,
  }),
  graphql(CONCILIATE_SHIPMENTS_MUTATION, {
    name: "conciliateShipments",
  }),
)(ConciliateShipments);
