import styled from "styled-components";

export const OperatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding-left: 23px;
  padding-right: 23px;
  margin-top: 18px;
`;

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 10px;
`;

export const TeamContainer = styled(AvatarsContainer)`
  margin-bottom: 18px;
`;
