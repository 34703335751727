import React from "react";
import "./ServiceOrderMap.css";
import { Card } from "reactstrap";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import MarkeredGoogleMaps from "components/GoogleMaps/MarkeredGoogleMaps";
import ClickableStatusIcon from "components/ClickableStatusIcon/ClickableStatusIcon";
import { compose } from "react-apollo";
import { withQuery } from "containers/withQuery.jsx";
import SERVICE_ORDER_POSITIONS_QUERY from "graphql/queries/get/serviceOrderPositionsQuery";
import { withMapsConsumer } from "contexts/MapsContext/MapsContext";
import MapsContainer from "contexts/MapsContext/MapsContainer";
import { withPolling } from "utils/withPolling";
import {
  GoogleMapsContainer,
  SearchAndMapsContainer,
  Travels
} from "./googleMapsComponents";

const travelTypeMap = {
  FINISHED: "concluida",
  IN_PROGRESS: "caminho",
  LOADING: "carregando",
  WAITING: "aguardando",
};

class ServiceOrderMap extends React.Component {
  state = {
    selectedTravel: null,
  }

  onTravelSelect = travel => () => {
    const { mapsContext: { entities } } = this.props;
    const selectedTravel = entities.find(entity => entity.uin === travel.uin);
    if (!selectedTravel || !travel.isVisibleOnMaps) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        "Esse carregamento foi concluído ou está aguardando."
      );
      return;
    }
    this.setState({
      selectedTravel,
    });
  }

  render() {
    const { serviceOrder, mapsContext } = this.props;
    const { selectedTravel } = this.state;
    const contractPosition = { lat: Number(serviceOrder.contract.latitude), lng: Number(serviceOrder.contract.longitude) };

    const travels = serviceOrder.equipmentShipments
      .map(({ equipment, status }) => ({
        uin: equipment.uin,
        type: travelTypeMap[status],
        isVisibleOnMaps: status === "IN_PROGRESS" || status === "LOADING"
      }));

    return (
      <GoogleMapsContainer>
        <SearchAndMapsContainer>
          <MarkeredGoogleMaps
            defaultZoom={20}
            contractPosition={contractPosition}
            travels={mapsContext.entities}
            selectedTravel={selectedTravel}
          />
        </SearchAndMapsContainer>
        <Travels>
          {travels && mapsContext.entitiesLoaded && (
            travels.map(travel => (
              <ClickableStatusIcon key={travel.uin} type={travel.type} onClick={this.onTravelSelect(travel)} />
            ))
          )}
        </Travels>
      </GoogleMapsContainer>
    );
  }
}

const ServiceOrderMapWithPooling = compose(
  withSnackbarConsumer,
  withMapsConsumer,
  withPolling((props) => ({
    pollingFunction: props.mapsContext.refreshEntitiesPosition,
    timeInterval: 1000 * 10,
  })),
)(ServiceOrderMap);


const ServiceOrderMapCard = props => {
  const entities = props.serviceOrder.equipmentShipments
    .filter(({ status, equipment }) => (status === "IN_PROGRESS" || status === "LOADING") && equipment.uin !== null)
    .map(({ equipment, status }) => ({
      id: equipment.id,
      uin: equipment.uin,
      status,
      name: equipment.code
    }));
  return (
    <Card>
      <MapsContainer entities={entities}>
        <ServiceOrderMapWithPooling {...props} />
      </MapsContainer>
    </Card>
  );
};

const ServiceOrderMapQuery = withQuery(({ serviceOrderId }) => ({
  query: SERVICE_ORDER_POSITIONS_QUERY,
  variables: {
    id: serviceOrderId,
  },
  skip: !serviceOrderId
}))(ServiceOrderMapCard);

export default ServiceOrderMapQuery;
