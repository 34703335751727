import React, { Component } from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import PencilButton from "components/ActionButtons/PencilButton";
import { LOCATIONS_QUERY } from "../generalRegisterQueries";
import LocationEdit from "./LocationEdit";

const headers = [
  {
    label: "Local", key: "name",
  },
  {
    label: "Capacidade", key: "capacity",
  },
  {
    label: "", key: "action",
  },
];

class LocationsTable extends Component {
  state = {
    currentEditId: null,
  }

  onFinishEdit = () => this.setState({ currentEditId: null });

  render() {
    const { currentEditId } = this.state;
    return (
      <React.Fragment>
        <Table
          maxHeight={400}
          headers={headers}
          matchHeaders
          items={this.props.inventoryLocations.map(location => ({
            ...location,
            action: (
              <PencilButton
                onPencilEditClick={() => this.setState({
                  currentEditId: location.id
                })}
              />
            )
          }))}
        />
        {currentEditId !== null && (
          <LocationEdit id={currentEditId} onFinishEdit={this.onFinishEdit} />
        )}
      </React.Fragment>
    );
  }
}

export default withQuery({ query: LOCATIONS_QUERY })(LocationsTable);
