import styled from "styled-components";

const CONTAINER_COLOR_MAPPER = {
  "WAITING": "#fcf4f4",
  "LOADING": "#f4f5fc",
  "IN_PROGRESS": "#fcfbf4",
};

export const Container = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ status }) => CONTAINER_COLOR_MAPPER[status] || "#F6F8FF"};
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 11px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin-top: 3px;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 33px;
  font-family: 'Rubik';
  color: #8798AD;
  text-align: right;
`;

export const Detail = styled.p`
  margin: 6px;
`;
