import React from "react";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";
import "./MapsSearcher.css";
import { renderInputComponent, renderLayout, renderTag } from "./mapsSearcherComponents";


class MapsSearcher extends React.Component {
  handleTagsinput = tagsinput => {
    if (tagsinput.length > 0) {
      const { name } = tagsinput[tagsinput.length - 1];
      const element = this.props.tags.find(tag => name === tag.name);

      if (element) {
        this.props.onTagsinputChange(tagsinput);
      }
    } else {
      this.props.onTagsinputChange(tagsinput);
    }
  };

  render() {
    return (
      <TagsInput
        renderInput={({ addTag, ...props }) => {
          const handleOnChange = (e, { method }) => {
            if (method === "enter") {
              e.preventDefault();
            } else {
              props.onChange(e);
            }
          };

          const inputValue = (props.value && props.value.trim().toLowerCase()) || "";
          const inputLength = inputValue.length;

          const suggestions = this.props.tags.filter(tag => {
            const tagNotInserted = !this.props.tagsinput.some(taginput => taginput.name === tag.name);
            const valueEqualsTagName = tag.name.toLowerCase().slice(0, inputLength) === inputValue;
            return valueEqualsTagName && tagNotInserted;
          });

          return (
            <Autosuggest
              ref={props.ref}
              suggestions={suggestions}
              getSuggestionValue={suggestion => suggestion.name}
              renderSuggestion={suggestion => <div>{suggestion.name}</div>}
              inputProps={{ ...props, onChange: handleOnChange }}
              onSuggestionSelected={(e, { suggestion }) => {
                addTag(suggestion);
              }}
              renderInputComponent={renderInputComponent}
              onSuggestionsClearRequested={() => {}}
              onSuggestionsFetchRequested={() => {}}
            />
          );
        }}
        renderTag={renderTag}
        renderLayout={renderLayout}
        value={this.props.tagsinput}
        onChange={this.handleTagsinput}
        tagProps={{ className: "react-tagsinput-tag success" }}
      />
    );
  }
}

export default MapsSearcher;
