import gql from "graphql-tag";

export const NEXT_LOAD_QUERY = gql`
  query equipmentShipment($id: Int!) {
    equipmentShipment(id: $id) {
      id
      programmedStartTime
      equipment {
        id
        uin
      }
      invoice {
        id
        startTime
      }
      serviceOrder {
        id
        startTime
      }
      contract {
        id
        latitude
        longitude
      }
    }
  }
`;

export const DISTANCE_MATRIX_QUERY = gql`
  query DISTANCE_MATRIX_QUERY($originLat: Decimal!, $originLong: Decimal!, $destLat: Decimal!, $destLong: Decimal!) {
    distanceMatrix(
      originLat: $originLat,
      originLong: $originLong,
      destLat: $destLat,
      destLong: $destLong
    ) {
      distance
      duration
    }
  }
`;
