import React from "react";
import { List, AutoSizer } from "react-virtualized";
import styled from "styled-components";
import {
  DropdownItem as BSDropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  DropdownMenu as BSDropdownMenu
} from "reactstrap";
import PlaceholderImg from "assets/img/operator-placeholder.jpg";

const TextItem = styled.p`
  font-family: 'Rubik';
  margin-left: 10px;
  font-size: 15px;
  color: #8798AD;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const DropdownItem = styled(BSDropdownItem)`
  display: flex !important;
  flex-direction: row;
  align-items: center;
`;

class DropdownMenu extends React.Component {
  renderRow = ({ index, key, style }) => {
    const operator = this.props.operators[index];
    return (
      <div key={key} style={style}>
        <DropdownItem key={operator.id} onClick={() => this.props.onClick(operator)}>
          <img
            src={operator.photo || PlaceholderImg}
            alt="operator"
            style={{ borderRadius: "50%", width: "35px", height: "35px" }}
          />
          <TextItem>{operator.name}</TextItem>
        </DropdownItem>
      </div>
    );
  }

  render() {
    const { operators, onChangeText } = this.props;
    return (
      <BSDropdownMenu right style={{ overflow: "scroll" }}>
        <div style={{ padding: "15px" }}>
          <InputGroup className="no-border" style={{ width: "100%", marginTop: "auto", marginBottom: "auto" }}>
            <Input defaultValue="" onChange={onChangeText} type="text" />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i className="nc-icon nc-zoom-split" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div style={{ width: "300px", height: "200px" }}>
          <AutoSizer>
            {({ width }) => (
              <List
                width={width}
                height={200}
                rowHeight={50}
                rowRenderer={this.renderRow}
                rowCount={operators.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </div>
      </BSDropdownMenu>
    );
  }
}

export default DropdownMenu;
