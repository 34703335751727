import styled from "styled-components";

export const GoogleMapsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 529px;
  overflow: hidden;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const SearchAndMapsContainer = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  padding-left: 26px;
  padding-top: 22px;
  padding-bottom: 22px;

  border: 1px solid red;
`;

export const Travels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 20px;
  padding-right: 20px;
`;
