import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";
import gql from "graphql-tag";
import { Query } from "react-apollo";

const ErrorContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 247px;
`;

export const withIterTokenFetch = Component => {
  const Wrapper = (props) => (
    <Query query={gql`{ iterToken }`}>
      {({ data, loading, error }) => {
        if (loading) {
          return null;
        }

        if (error) {
          return (
            <ErrorContainer>
              <h3>Serviço de GPS indisponível.</h3>
              <p style={{ marginTop: "7px" }}>
                Recarregue a página em alguns instantes para tentar habilitá-lo.
              </p>
            </ErrorContainer>
          );
        }

        return <Component {...data} {...props} />;
      }}
    </Query>
  );

  return Wrapper;
};
