import React from "react";
import "./LoadSlider.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const MIN_VALUE = 0.5;
const MAX_VALUE = 11;
const STEP = 0.5;

const LoadSlider = ({ onChange, value = MIN_VALUE, lightText }) => {
  const color = lightText ? "#fff" : "#2E384D";
  return (
    <div className="load-slider-container">
      <div className="load-slider-info">
        <h3 style={{ color }}>Quantidade</h3>
        <p>
          {`${value}m³`}
        </p>
      </div>

      <Slider
        onChange={onChange}
        value={parseFloat(value)}
        min={MIN_VALUE}
        max={MAX_VALUE}
        step={STEP}
        style={{ marginTop: "10px", color: "#1D84C6" }}
      />
    </div>
  );
};

export default LoadSlider;
