import React from "react";
import { Card } from "reactstrap";
import { format } from "date-fns";
import { Query, compose } from "react-apollo";
import { withQuery } from "containers/withQuery";
import { getIn } from "utils/nesting";
import { withMapsConsumer } from "contexts/MapsContext/MapsContext";
import Spinner from "components/Spinner/Spinner";
import {
  Container,
  Content,
  ProgressLine
} from "./progressBarComponents";
import { NEXT_LOAD_QUERY, DISTANCE_MATRIX_QUERY } from "./progressBarQueries";

const toDateTime = time => {
  const timeArray = time
    ? time.split(":")
    : [0, 0, 0];

  return new Date().setHours(...timeArray, 0);
};

const ProgressContainer = ({ children }) => (
  <Card>
    <Container>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {children}
      </div>
    </Container>
  </Card>
);

class ProgressBar extends React.Component {
  render() {
    const { equipmentShipment, mapsContext } = this.props;

    if (mapsContext.equipmentLoading) {
      return (
        <ProgressContainer>
          <Spinner />
        </ProgressContainer>
      );
    }
    const originLat = getIn(["equipment", "position", "lat"], mapsContext);
    const originLong = getIn(["equipment", "position", "lng"], mapsContext);
    const destLat = getIn(["contract", "latitude"], equipmentShipment);
    const destLong = getIn(["contract", "longitude"], equipmentShipment);

    if (!originLat || !originLong) {
      return (
        <ProgressContainer>
          <p>
            A estimativa de tempo não pode ser calculada.
          </p>
        </ProgressContainer>
      );
    }

    return (
      <Query
        query={DISTANCE_MATRIX_QUERY}
        variables={{
          originLat,
          originLong,
          destLat,
          destLong
        }}
      >
        {
          ({ data, loading }) => {
            if (loading) {
              return (
                <ProgressContainer>
                  <Spinner />
                </ProgressContainer>
              );
            }

            if (!data || !data.distanceMatrix) {
              return (
                <ProgressContainer>
                  <p>
                    Erro ao calcular a distância.
                  </p>
                </ProgressContainer>
              );
            }

            const twentyMinutesMs = 20 * 60 * 1000;
            const duration = getIn(["distanceMatrix", "duration"], data);
            const invoiceStartime = getIn(["invoice", "startTime"], equipmentShipment);

            let startTime = invoiceStartime || toDateTime(equipmentShipment.programmedStartTime);
            startTime = new Date(startTime).getTime();

            // transform duration to mseconds and add 15% to the duration
            const estimatedDate = new Date(startTime + duration * 1150 + twentyMinutesMs);
            const dateNowMS = new Date().getTime();
            const lapsed = (dateNowMS - startTime) > 0 ? (dateNowMS - startTime) : 0;
            const percent = lapsed / (duration * 1150 + twentyMinutesMs);

            return (
              <Card>
                <Container>
                  <ProgressLine
                    percent={percent * 100}
                    strokeWidth="1"
                    strokeColor="#1D84C6"
                  />
                  <Content>
                    <p>
                      Início
                      <span className="h3">
                        {format(startTime, "HH:mm:ss")}
                      </span>
                    </p>
                    <p>
                      Estimado de entrega
                      <span className="h3">
                        {format(estimatedDate, " HH:mm:ss")}
                      </span>
                    </p>
                  </Content>
                </Container>
              </Card>
            );
          }
        }
      </Query>
    );
  }
}


export default compose(
  withQuery(({ shipmentId }) => ({
    query: NEXT_LOAD_QUERY,
    variables: { id: shipmentId },
    skip: !shipmentId
  })),
  withMapsConsumer,
)(ProgressBar);
