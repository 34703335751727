import React from "react";
import ReactSVG from "react-svg";
import { Card } from "reactstrap";
import { withQuery } from "containers/withQuery";
import Truck from "assets/img/blue-truck.svg";
import LoadCard from "components/LoadCard/LoadCard";
import OperatorsList from "components/OperatorsList/OperatorsList";
import LoadSlider from "components/LoadSlider/LoadSlider";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import TeamInfo from "components/TeamInfo/TeamInfo";
import { OperatorContainer, TeamContainer } from "components/TeamInfo/teamInfoComponents";
import { LOAD_QUERY } from "./loadInfoQueries";
import {
  StyledButton,
  InfoContainer,
  EmptyInfoContainer,
  InfoText,
  EquipmentContainer,
  TruckContainer,
  EquipmentCode,
} from "./loadInfoComponents";

class Content extends React.Component {
  state = {
    isOpen: false,
    quantityValue: this.props.equipmentShipment ? Number(this.props.equipmentShipment.equipmentRequestOrder.quantity) : 5,
    teamSelected: [],
    operatorsSelected: [],
  }

  componentWillReceiveProps({ equipmentShipment }) {
    if (equipmentShipment && equipmentShipment.equipmentRequestOrder.quantity !== this.state.quantityValue) {
      this.setState({ quantityValue: equipmentShipment.equipmentRequestOrder.quantity });
    }
  }

  toggleModal = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))

  onChangeSlider = quantityValue => this.setState({ quantityValue });

  onOperatorChange = (key, operators) => this.setState({ [key]: operators });

  onTeamChange = teamSelected => this.setState({ teamSelected });

  render() {
    if (!this.props.equipmentShipment) {
      return null;
    }

    const {
      equipmentShipment: {
        client: { id, name },
        contract: {
          code,
          identification,
          neighborhood,
          number,
          city,
          state,
          country,
          address,
          cep,
          complement,
          placeComments
        },
        operators,
        ...equipmentShipment
      },
      formulaCode,
      formulaId,
      shipmentId,
    } = this.props;

    const modalData = {
      client: {
        id,
        name
      },
      contract: {
        code,
        identification,
        neighborhood,
        address,
        country,
        cep,
        complement,
        state,
        city,
        number,
      },
      equipmentCode: equipmentShipment.equipment.code,
      formulaCode,
      operatorsSelected: this.state.operatorsSelected,
      teamSelected: this.state.teamSelected
    };
    const { startTime } = equipmentShipment.invoice || {};

    return (
      <React.Fragment>
        <InfoContainer>
          <h3>Cliente</h3>
          <InfoText>{name}</InfoText>
          <InfoText>
            {`${code} - ${identification}`}
          </InfoText>
        </InfoContainer>

        <InfoContainer>
          <h3>Endereço</h3>
          <InfoText>
            {`${address}, ${number}, ${neighborhood}`}
          </InfoText>

          <InfoText>
            {complement}
            {placeComments && ` - ${placeComments}`}
          </InfoText>

          <InfoText>
            {`${city} - ${state}`}
          </InfoText>

          <InfoText>
            {cep}
          </InfoText>
        </InfoContainer>

        <EquipmentContainer>
          <div>
            <h3>Equipamento</h3>
            <EquipmentCode>{equipmentShipment.equipment.code}</EquipmentCode>
          </div>
          <TruckContainer>
            <ReactSVG src={Truck} />
          </TruckContainer>
        </EquipmentContainer>

        <LoadCard {...equipmentShipment} />
        <LoadSlider value={this.state.quantityValue} onChange={this.onChangeSlider} />

        {startTime
          ? (
            <OperatorContainer>
              <h3>Equipe</h3>
              <TeamContainer>
                <OperatorsList
                  type="operator"
                  operators={operators}
                />
              </TeamContainer>
            </OperatorContainer>
          ) : (
            <TeamInfo
              onOperatorChange={this.onOperatorChange}
              onTeamChange={this.onTeamChange}
            />
          )}

        {!startTime && (
          <StyledButton color="info" onClick={this.toggleModal}>
            Iniciar carregamento
          </StyledButton>
        )}

        {this.state.isOpen && (
          <ConfirmModal
            isOpen={this.state.isOpen}
            toggleModal={this.toggleModal}
            data={modalData}
            quantityValue={this.state.quantityValue}
            formulaCode={formulaCode}
            shipmentId={shipmentId}
            concreteFormulaId={formulaId}
          />
        )}
      </React.Fragment>
    );
  }
}

const ContentContainer = withQuery(({ shipmentId, formulaCode }) => ({
  query: LOAD_QUERY,
  variables: { id: shipmentId, formulaCode },
  skip: !shipmentId || !formulaCode
}))(Content);

const EmptyContent = () => (
  <EmptyInfoContainer>
    <h3>Sem carga selecionada!</h3>
    <InfoText>Selecione uma carga para visualizá-la</InfoText>
  </EmptyInfoContainer>
);

const LoadInfo = ({ shipmentId, formulaCode, formulaId }) => (
  <Card>
    {
      (shipmentId && formulaCode && formulaId)
        ? (
          <ContentContainer
            shipmentId={shipmentId}
            formulaCode={formulaCode}
            formulaId={formulaId}
          />
        )
        : (
          <EmptyContent />
        )
    }
  </Card>
);

export default LoadInfo;
