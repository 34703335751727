import gql from "graphql-tag";

export const CREATE_CONCRETE_SHIPMENT_MUTATION = gql`
  mutation(
    $params: ConcreteShipmentParams!,
    $operatorIds: [Int]
  ) {
    createConcreteShipment(
      params: $params,
      operatorIds: $operatorIds
    ) {
      concreteShipment {
        id
      }
      invoiceRequestError
    }
  }
`;
