/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { Modal, Button } from "reactstrap";
import { withQuery } from "containers/withQuery";
import { compose, graphql } from "react-apollo";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import CostBox from "./CostBox.jsx";
import CONCRETE_FORMULA_QUERY from "./concreteFormulaQueries.js";
import { CONCRETE_FORMULAS_QUERY } from "../FamiliesAndFormulasQueries";
import {
  InfoContainer,
  CostBoxContainer,
  TableContainer,
} from "./ConcreteTracesModalStyle.jsx";
import { UPDATE_CONCRETE_FORMULA_MUTATION } from "./concreteFormulaMutations.js";
import MaterialsTable from "./MaterialsTable.jsx";
import ConcreteFormulaHeader from "./ConcreteFormulaHeader.jsx";
import ConcreteFormulaBody from "./ConcreteFormulaBody.jsx";
import { UPDATE_REQUIRED_FIELDS, MATERIAL_REQUIRED_FIELDS, isBlank } from "./requiredFields";
import { calculateMortarFactor, calculateWcFactor } from "./calculations.js";
import getConcreteFamiliesOptions, { getConcreteFamilyName } from "./getConcreteFamiliesOptions";

class ConcreteTracesModal extends Component {
  constructor(props) {
    super(props);
    const {
      isActive,
      fcj,
      fck,
      use,
      wcRatio,
      fctmk,
      mortarFactor,
      observations,
      incorporatedAir,
      consumption,
      aggressivenessIndex,
      concreteFamily,
      margin,
      code,
    } = props.concreteFormula;

    this.state = {
      code,
      materialsHasChanged: false,
      isEditable: false,
      shouldUpdate: false,
      isActive,
      materials: [],
      margin: margin || 0,
      use,
      concreteFamily: {
        ...concreteFamily,
        value: concreteFamily.id,
        label: getConcreteFamilyName(concreteFamily.name, concreteFamily.slump),
      },
      fck,
      fcj,
      fctmk,
      consumption,
      mortarFactor,
      wcRatio,
      aggressivenessIndex,
      incorporatedAir,
      observations,
    };
  }

  onPencilEditClick = () => this.setState(({ isEditable }) => ({ isEditable: !isEditable, shouldUpdate: true }))

  onChangeActive = e => this.setState(({ isActive: e.state.value, shouldUpdate: false }))

  onChangeData = (key, name) => this.setState({ [key]: name, shouldUpdate: false });

  onChangeMaterials = materials => {
    this.setState(state => {
      const mortarFactor = state.isEditable ? calculateMortarFactor(materials) : state.mortarFactor;
      const wcRatio = state.isEditable ? calculateWcFactor(materials) : state.wcRatio;

      return {
        materials,
        materialsHasChanged: true,
        shouldUpdate: false,
        mortarFactor,
        wcRatio,
      };
    });
  }

  formatToR$ByVolume = value => (value !== "" ? `R\$\ ${parseFloat(value).toFixed(2)}/m³` : "-");

  isValid = (object, requiredFields) => {
    const { snackbarContext } = this.props;

    const missingFields = requiredFields.filter(field => {
      const value = object[field.key];

      if (field.select) {
        return !value || isBlank(value[field.select]);
      }

      return isBlank(value);
    });

    if (missingFields.length === 0) return true;

    const message = missingFields.length === 1
      ? `${missingFields[0].label} é obrigatório`
      : `${missingFields.map(({ label }) => label).join(", ")} são obrigatórios`;

    snackbarContext.setNotificationTimeOut(Color.danger, message);
    return false;
  };

  onSaveClick = () => {
    const {
      code,
      isActive,
      use,
      fck,
      fcj,
      fctmk,
      aggressivenessIndex,
      incorporatedAir,
      observations,
      materials,
      margin,
      concreteFamily,
      materialsHasChanged,
    } = this.state;

    if (!this.isValid(this.state, UPDATE_REQUIRED_FIELDS)) {
      return;
    }

    if (materials.length === 0) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "É necessário informar pelo menos 1 material.");
      return;
    }

    const invalidMaterial = materials.find(material => !this.isValid(material, MATERIAL_REQUIRED_FIELDS));

    if (invalidMaterial) {
      return;
    }

    this.props.updateConcreteFormula({
      variables: {
        id: this.props.id,
        params: {
          isActive,
          code,
          concreteFamilyId: concreteFamily.value,
          margin,
          use,
          fck: parseInt(fck, 10),
          fcj: parseInt(fcj, 10),
          fctmk: parseFloat(fctmk),
          aggressivenessIndex: parseFloat(aggressivenessIndex),
          incorporatedAir: parseFloat(incorporatedAir),
          observations,
          formulaMaterials: materialsHasChanged ? materials.map(material => ({
            inventoryItemId: material.name.value,
            order: material.id,
            proportion: parseFloat(material.proportion),
            weight: parseFloat(material.weight.label),
            specificWeight: parseFloat(material.specificWeight.label),
            supplierId: parseInt(material.supplier.value, 10)
          })) : undefined
        },
      },
      refetchQueries: [
        {
          query: CONCRETE_FORMULA_QUERY,
          variables: { id: this.props.id },
        },
        {
          query: CONCRETE_FORMULAS_QUERY,
          variables: { concreteFamilyIds: this.props.concreteFamilyIds }
        }
      ]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Traço atualizado com sucesso.");
        this.props.closeModalHandler();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const {
      code,
      isActive,
      use,
      concreteFamily,
      fck,
      fcj,
      fctmk,
      consumption,
      mortarFactor,
      wcRatio,
      aggressivenessIndex,
      incorporatedAir,
      margin,
      observations,
    } = this.state;

    const { price } = this.props.concreteFormula;

    const concreteFamiliesOptions = getConcreteFamiliesOptions(this.props.concreteFamilies);

    const total = (margin !== "" ? parseInt(margin, 10) : 0) + parseInt(price || 0, 10);

    return (
      <Modal
        isOpen={this.props.modalOpen}
        toggle={this.props.closeModalHandler}
        size="lg"
        style={{ maxWidth: "1600px", width: "80%" }}
      >
        <ConcreteFormulaHeader
          onPencilEditClick={this.onPencilEditClick}
          onChangeActive={this.onChangeActive}
          closeModalHandler={this.props.closeModalHandler}
          isActive={isActive}
        />
        <ConcreteFormulaBody
          code={code}
          isEditable={this.state.isEditable}
          use={use}
          concreteFamily={concreteFamily}
          concreteFamiliesOptions={concreteFamiliesOptions}
          fck={fck}
          fcj={fcj}
          fctmk={fctmk}
          consumption={consumption}
          mortarFactor={mortarFactor}
          wcRatio={wcRatio}
          aggressivenessIndex={aggressivenessIndex}
          incorporatedAir={incorporatedAir}
          observations={observations}
          onChangeData={this.onChangeData}
        />
        <TableContainer className="modal-body" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          <MaterialsTable
            materials={this.props.concreteFormula.formulaMaterials}
            onChangeMaterials={this.onChangeMaterials}
            inventoryItems={this.props.inventoryItems}
            isEditable={this.state.isEditable}
            shouldUpdate={this.state.shouldUpdate}
          />
          <InfoContainer>
            <CostBoxContainer>
              <CostBox
                title="Custo total do traço"
                value={price}
                formatter={this.formatToR$ByVolume}
              />
              <CostBox
                isEditable={this.state.isEditable}
                keyValue="margin"
                onChange={this.onChangeData}
                title="Margem"
                value={margin !== 0 ? margin : 0}
                formatter={this.formatToR$ByVolume}
              />
              <CostBox
                title="Preço"
                value={total}
                formatter={this.formatToR$ByVolume}
              />
            </CostBoxContainer>
          </InfoContainer>
        </TableContainer>
        <div className="modal-footer">
          <Button
            style={{ width: "100%", padding: "17px 16px", margin: 0 }}
            color="info"
            data-dismiss="modal"
            type="button"
            onClick={this.onSaveClick}
          >
            Salvar Traço
          </Button>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withSnackbarConsumer,
  graphql(UPDATE_CONCRETE_FORMULA_MUTATION, { name: "updateConcreteFormula", }),
  withQuery(({ id }) => ({
    noLoader: true,
    query: CONCRETE_FORMULA_QUERY,
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
  })),
)(ConcreteTracesModal);
