import React from "react";
import { Card } from "reactstrap";
import ReactSVG from "react-svg";
import Sun from "assets/img/sol.svg";
import Cloud from "assets/img/cloudy.svg";
import Rain from "assets/img/rain.svg";
import Wind from "assets/img/wind.svg";
import { format } from "date-fns";
import { withQuery } from "containers/withQuery";
import { compose, Query } from "react-apollo";
import gql from "graphql-tag";
import {
  TimeWithWeatherContainer,
  InfoContainer,
  VerticalLine,
  TemperatureContainer,
  Hour,
  Temperature
} from "./timeWithWeatherComponents";


const CONTRACT_LOCATION_QUERY = gql`
  query ContractLocationQuery($id: Int!) {
    serviceOrder(id: $id) {
      id
      contract {
        id
        cep
        city
        latitude
        longitude
      }
    }
  }
`;

const WEATHER_NOW_QUERY = gql`
  query WeatherNowQuery($lat: Decimal!, $long: Decimal!) {
    weatherNow(lat: $lat, long: $long) {
      icon
      temperature
    }
  }
`;

const Weather = (image) => {
  const { icon } = image;
  let src;
  if (icon === "cloudy" || icon === "partly-cloudy-day") {
    src = Cloud;
  } else if (icon === "rain") {
    src = Rain;
  } else if (icon === "wind") {
    src = Wind;
  } else {
    src = Sun;
  }
  return (
    <ReactSVG src={src} />
  );
};
class TimeWithWeather extends React.Component {
  state = {
    time: new Date()
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: new Date()
    });
  }

  render() {
    const { serviceOrderId, serviceOrder } = this.props;
    const city = serviceOrder && serviceOrder.contract && serviceOrder.contract.city;
    const lat = serviceOrder && serviceOrder.contract && serviceOrder.contract.latitude;
    const long = serviceOrder && serviceOrder.contract && serviceOrder.contract.longitude;
    return (
      <Query
        query={WEATHER_NOW_QUERY}
        variables={{
          lat,
          long,
        }}
        skip={!lat || !long}
      >
        {
          ({ data }) => (
            <Card className="card-stats">
              <TimeWithWeatherContainer>
                <InfoContainer>
                  <p>{serviceOrderId ? city : "-"}</p>
                  <Hour>
                    {format(this.state.time, "HH:mm:ss")}
                  </Hour>
                </InfoContainer>

                <VerticalLine />

                <TemperatureContainer>
                  <Weather
                    icon={
                      data
                      && data.weatherNow
                      && data.weatherNow.icon
                    }
                  />

                  <Temperature>
                    {
                      data
                      && data.weatherNow
                      && `${parseFloat(data.weatherNow.temperature).toFixed(0)}°`
                    }
                  </Temperature>
                </TemperatureContainer>
              </TimeWithWeatherContainer>
            </Card>
          )
        }
      </Query>
    );
  }
}

export default compose(
  withQuery(({ serviceOrderId }) => ({
    query: CONTRACT_LOCATION_QUERY,
    variables: {
      id: serviceOrderId,
    },
    skip: !serviceOrderId
  }))
)(TimeWithWeather);
