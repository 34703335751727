import React from "react";
import { format } from "date-fns";
import {
  Container,
  Text,
  Title,
  Detail
} from "./loadCardComponents";

const getShipmentStatus = (startTime, travelStartTime) => {
  if (startTime && travelStartTime) return "IN_PROGRESS";
  if (startTime) return "LOADING";
  return "WAITING";
}

const LoadCard = ({
  serviceDetail,
  equipment: { code: equipmentCode },
  serviceOrder: { code: SOCode },
  programmedStartTime,
  equipmentRequestOrder: { quantity },
  invoice,
}) => {
  const {
    startTime,
    quantity: invoiceQuantity,
    travelStartTime
  } = invoice || {};

  return (
    <Container status={getShipmentStatus(startTime, travelStartTime)}>
      <div>
        <h3>{`OS ${SOCode}`}</h3>
        <Text>{equipmentCode}</Text>
        <Text>{startTime ? format(startTime, "HH:mm") : programmedStartTime.slice(0, 5)}</Text>
      </div>

      <div>
        <Title>{`${startTime ? parseFloat(invoiceQuantity).toFixed(1) : parseFloat(quantity).toFixed(1)} m³`}</Title>
        <Detail>{serviceDetail && serviceDetail.name}</Detail>
      </div>
    </Container>
  );
};

export default LoadCard;
