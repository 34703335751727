import gql from "graphql-tag";

export const PENDING_SHIPMENTS = gql`
  query {
    concreteShipments(
      hasEquipment: false,
      status: IN_PROGRESS
    ) {
      id
      sealNumber
      loadingStartTime
      quantity
      concreteFormula {
        id
        code
      }
    }
    equipmentShipments(inProgress: false) {
      id
      programmedStartTime
      equipmentRequestOrder {
        id
        quantity
      }
      equipment {
        id
        code
      }
    serviceOrder {
        id
        code
        serviceRequestOrder {
          id
          service {
            id
            code
          }
        }
      }
    }
  }
`;

export const CONCILIATE_SHIPMENTS_MUTATION = gql`
mutation (
  $concreteShipmentId: Int!,
  $equipmentShipmentId: Int!,
  $operatorIds: [Int],
) {
  conciliateConcreteShipment (
    concreteShipmentId: $concreteShipmentId,
    equipmentShipmentId: $equipmentShipmentId,
    operatorIds: $operatorIds,
  ) {
    concreteShipment {
      id
    }
    invoiceRequestError
  }
}
`;
