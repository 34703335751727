import React from "react";
import { Card } from "reactstrap";
import { format } from "date-fns";

import styled from "styled-components";
import { withQuery } from "containers/withQuery";
import {
  Container,
  Title,
  Cell,
  Table,
  Row
} from "./serviceOrdersComponents";
import { INVOICES_QUERY } from "./serviceOrdersQueries";

const OSHistory = styled.div`
  max-height: 529px;
  overflow: auto;
`;

const TableContent = ({ invoices, noServiceOrdersHandler }) => {
  if (invoices && invoices.length === 0) {
    noServiceOrdersHandler();
  }

  return (
    <Table>
      <tbody>
        {(invoices || []).map(invoice => (
          <Row key={invoice.sealNumber}>
            <Cell>{invoice.sealNumber}</Cell>
            <Cell>
              {invoice.quantity}
              m³
            </Cell>
            <Cell>{format(invoice.startTime, "DD/MM/YYYY HH:mm")}</Cell>
          </Row>
        ))}
      </tbody>
    </Table>
  );
};

const TableContainer = withQuery(({ serviceOrderId }) => ({
  query: INVOICES_QUERY,
  variables: { serviceOrderId },
  skip: !serviceOrderId
}))(TableContent);

const ServiceOrders = ({ serviceOrderId, noServiceOrdersHandler }) => (
  <Card>
    <Container>
      <OSHistory>
        <Title>Histórico de Viagens</Title>
        <TableContainer
          serviceOrderId={serviceOrderId}
          noServiceOrdersHandler={noServiceOrdersHandler}
        />
      </OSHistory>
    </Container>
  </Card>
);

export default ServiceOrders;
