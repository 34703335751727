import React from "react";
import ReactSVG from "react-svg";
import operatorTag from "assets/img/operator-tag.svg";
import truckTag from "assets/img/truck-tag.svg";

const tagsImage = {
  truckTag,
  operatorTag,
};

export const renderTag = ({
  tag,
  key,
  disabled,
  onRemove,
  classNameRemove,
  getTagDisplayValue,
  ...other
}) => (
  <span key={key} {...other}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ReactSVG
        src={tagsImage[tag.type]}
        svgStyle={{ width: 20, marginRight: "5px", }}
      />
      {tag.name}
      {!disabled && (
        // eslint-disable-next-line
        <a className={classNameRemove} onClick={() => onRemove(key)} />
      )}
    </div>
  </span>
);

export const renderLayout = (
  tagComponents,
  inputComponent
) => (
  <span
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap"
    }}
  >
    {inputComponent}
    {tagComponents}
  </span>
);

export const renderInputComponent = ({
  onChange,
  value,
  addTag,
  placeholder,
  ...other
}) => (
  <input
    type="text"
    placeholder="Procure equipamentos e operadores"
    onChange={onChange}
    value={value}
    {...other}
    style={{ width: "225px" }}
  />
);
