import React from "react";
import { CircularIcon } from "components/StyledComponents";
import ButtonLoader from "components/ButtonLoader/ButtonLoader";
import { Tooltip } from "reactstrap";

const ICON_SIZE = 30;

class InvoiceAction extends React.Component {
  state = {
    open: false,
  }

  toggle = () => this.setState(state => ({ open: !state.open }));

  render() {
    const {
      loading,
      icon,
      color,
      onClick,
      tooltip,
      buttonId,
    } = this.props;
    const {
      open
    } = this.state;
    const tooltipId = `tooltip${buttonId}`;

    return (
      <React.Fragment>
        <Tooltip
          placement="top"
          isOpen={open}
          target={tooltipId}
        >
          {tooltip}
        </Tooltip>
        <CircularIcon
          onMouseEnter={this.toggle}
          onMouseLeave={this.toggle}
          backgroundColor={color}
          size={ICON_SIZE}
          onClick={onClick}
          id={tooltipId}
        >
          {
            loading
              ? <ButtonLoader />
              : <i className={icon} />
          }
        </CircularIcon>
      </React.Fragment>
    );
  }
}

export default InvoiceAction;
