import React from "react";
import {
  Row, Col
} from "reactstrap";
import { withQuery } from "containers/withQuery";
import { compose } from "react-apollo";
import styled from "styled-components";

import SERVICE_ORDERS_QUERY from "graphql/queries/get/serviceOrdersQuery";
import { withServiceOrdersFilterConsumer } from "contexts/ServiceOrdersFilterContext";
import ServiceOrder from "./ServiceOrder";

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;


class ServiceOrders extends React.Component {
  render() {
    const { serviceOrders } = this.props.serviceOrdersTable;
    return (
      <React.Fragment>
        <div className="content">
          <Row>
            {serviceOrders.length
              ? serviceOrders.map(data => (
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  key={data.code}
                >
                  <ServiceOrder osData={data} />
                </Col>
              ))
              : (
                <NotFound>
                  <p>
                    Nenhuma OS foi encontrada
                  </p>
                </NotFound>
              )
            }
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withServiceOrdersFilterConsumer,
  withQuery(({ serviceOrdersFilterContext }) => ({
    query: SERVICE_ORDERS_QUERY,
    variables: {
      soStatus: serviceOrdersFilterContext.value,
    }
  }))
)(ServiceOrders);
