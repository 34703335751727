import styled from "styled-components";

export const Container = styled.div`
  text-align: left;
  height: 100%;
  min-height: 100%;
`;

export const Title = styled.h3`
  padding-left: 26px;
  padding-top: 22px;
  padding-bottom: 22px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Cell = styled.td`
  padding: 14px 28px 14px 28px;
  font-size: 13px;
  color: #8798AD;
`;

export const Row = styled.tr`
  &:nth-child(odd) {
    background-color: #F6F8FF;
  }
`;
