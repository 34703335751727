import React from "react";
import "./GoogleMapsCard.css";
import { Card } from "reactstrap";
import StatusIcon from "components/StatusIcon/StatusIcon";
import { compose } from "react-apollo";
import { withQuery } from "containers/withQuery.jsx";
import { withMapsConsumer } from "contexts/MapsContext/MapsContext";
import { getIn } from "utils/nesting";
import MarkeredGoogleMaps from "components/GoogleMaps/MarkeredGoogleMaps";
import { withPolling } from "utils/withPolling";
import {
  GoogleMapsContainer,
  SearchAndMapsContainer,
  Travels,
} from "./googleMapsComponents";
import MapsSearcher from "./MapsSearcher";
import { NEXT_SHIPMENT_AND_SERVICE_ORDER_QUERY, ALL_EQUIPMENTS_AND_OPERATORS_QUERY } from "./googleMapsCardQueries";

const createTags = (equipments = [], operators = []) => {
  const tagsEquipment = equipments
    .filter(equipment => equipment.uin !== null)
    .map(equipment => ({
      type: "truckTag",
      name: equipment.code,
      uin: equipment.uin
    }));

  const tagsOperator = operators
    .filter(operator => operator.uin !== null)
    .map(operator => ({
      type: "operatorTag",
      name: operator.name,
      uin: operator.uin
    }));

  return tagsEquipment.concat(tagsOperator);
};

class Maps extends React.Component {
  render() {
    const {
      travels, mapsContext, equipmentShipment, equipments, operators
    } = this.props;
    const tagNames = mapsContext.entities.map(tag => tag.name);
    const tags = createTags(equipments, operators);
    return (
      <GoogleMapsContainer>
        <SearchAndMapsContainer>
          <MapsSearcher
            tagsinput={mapsContext.entities}
            onTagsinputChange={tagsinput => {
              mapsContext.addEntity(tagsinput);
            }}
            tags={tags}
          />
          <MarkeredGoogleMaps
            defaultZoom={20}
            contractPosition={equipmentShipment ? {
              lat: Number(getIn(["serviceOrder", "contract", "latitude"], equipmentShipment)),
              lng: Number(getIn(["serviceOrder", "contract", "longitude"], equipmentShipment))
            } : null}
            travels={mapsContext.entities.filter(equipment => (
              tagNames.includes(equipment.name)
            ))}
            selectedTravel={mapsContext.equipment}
          />
        </SearchAndMapsContainer>
        {travels && (
          <Travels>
            {travels.map(travel => (
              <StatusIcon key={travel.id} type={travel.type} />
            ))}
          </Travels>
        )}
      </GoogleMapsContainer>
    );
  }
}

const GoogleMapsManagement = compose(
  withQuery(({ shipmentId }) => ({
    query: NEXT_SHIPMENT_AND_SERVICE_ORDER_QUERY,
    variables: {
      shipmentId
    },
    skip: !shipmentId,
    fetchPolicy: "no-cache",
  })),
  withQuery({
    query: ALL_EQUIPMENTS_AND_OPERATORS_QUERY,
  }),
  withMapsConsumer,
  withPolling(({ mapsContext }) => ({
    pollingFunction: mapsContext.refreshEntitiesPosition,
    timeInterval: 1000 * 70,
  })),
  withPolling(({ equipmentShipment, mapsContext }) => ({
    pollingFunction: () => mapsContext.setEquipmentPosition(
      equipmentShipment ? equipmentShipment.equipment : null
    ),
    timeInterval: 1000 * 30,
  }))
)(Maps);

export default ({ shipmentId }) => (
  <Card>
    <div style={{
      width: "100%", height: "100%", display: "flex", justifyContent: "center"
    }}
    >
      <GoogleMapsManagement shipmentId={shipmentId} />
    </div>
  </Card>
);
