import gql from "graphql-tag";

export const NEXT_SHIPMENT_AND_SERVICE_ORDER_QUERY = gql`
  query equipmentShipment($shipmentId: Int!) {
    equipmentShipment(id: $shipmentId) {
      id
      equipment {
        id
        code
        uin
      }
      serviceOrder {
        id
        code
        contractId
        contract {
          id
          address
          cep
          city
          number
          neighborhood
          state
          clientId
          latitude
          longitude
        }
      }
    }
  }
`;

export const ALL_EQUIPMENTS_AND_OPERATORS_QUERY = gql`
  query {
    operators {
      id
      name
      uin
    }
    equipments {
      id
      code
      uin
    }
  }
`;
