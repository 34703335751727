import gql from "graphql-tag";

export default gql`
query ServiceOrders($soStatus: SoCurrentStateEnum!) {
  serviceOrdersTable(filters: {currentState: $soStatus}) {
    serviceOrders {
      id
      code
      shipmentsProgress
      contractId
      equipmentShipments {
        id
        status
      }
      contract {
        id
        address
        cep
        city
        number
        neighborhood
        state
        clientId
      }
    }
  }
}
`;
