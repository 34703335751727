export const chartData = (shipmentsProgress) => {
  const parts = shipmentsProgress.split("/");
  const first = parseInt(parts[0], 10);
  const second = parseInt(parts[1], 10);

  return ({
    datasets: [
      {
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#4acccd", "#f4f3ef"],
        borderWidth: 0,
        data: shipmentsProgress === "0/0" ? [0, 1] : [first, (second - first)]
      }
    ]
  });
};

export const chartOptions = (shipmentsProgress) => ({
  elements: {
    center: {
      text: shipmentsProgress,
      color: "#2E384D", // Default is #000000
      fontStyle: "Rubik", // Default is Arial
      // sidePadding: 60 // Defualt is 20 (as a percentage)
    }
  },
  cutoutPercentage: 80,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: "transparent",
          color: "rgba(255,255,255,0.05)"
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          display: false
        }
      }
    ]
  }
});
