import React from "react";
import {
  Row,
  Col,
} from "reactstrap";

import MapsContainer from "contexts/MapsContext/MapsContainer";
import BoxInfo from "./BoxInfo/BoxInfo";
import TimeWithWeather from "./TimeWithWeather/TimeWithWeather";
import ProgressBar from "./ProgressBar/ProgressBar";
import LoadInfo from "./LoadInfo/LoadInfo";
import ServiceOrders from "./ServiceOrders/ServiceOrders";
import GoogleMapsCard from "./GoogleMapsCard/GoogleMapsCard";
import LoadsCard from "./LoadsCard/LoadsCard";

import {
  NEXT_TRIP_QUERY,
  TOTAL_VOLUME_QUERY
} from "./dashboardQueries";

class Dashboard extends React.Component {
  state = {
    shipmentId: null,
    serviceOrderId: null,
    formulaCode: null,
    noServiceOrders: false,
  }

  changeSelectedShipment = ({
    id: shipmentId,
    serviceOrder: {
      id: serviceOrderId,
      serviceRequestOrder,
    },
  }) => {
    this.setState({
      shipmentId,
      serviceOrderId,
      formulaCode: serviceRequestOrder.service.code,
      formulaId: serviceRequestOrder.service.id,
    });
  }

  onNoServiceOrders = () => {
    const { noServiceOrders } = this.state;
    if (!noServiceOrders) {
      this.setState({
        noServiceOrders: true,
      });
    }
  }

  render() {
    const {
      serviceOrderId,
      shipmentId,
      formulaCode,
      formulaId,
      noServiceOrders,
    } = this.state;

    return (
      <div className="content">
        <Row>
          <Col xl="9" lg="12">
            <Row>
              <Col xl="6" lg="12" md="12" xs="12">
                <TimeWithWeather
                  serviceOrderId={serviceOrderId}
                />
              </Col>

              <Col xl="3" lg="6" md="6" xs="12">
                <BoxInfo
                  queryOptions={{
                    query: NEXT_TRIP_QUERY,
                    variables: { shipmentId },
                    skip: !shipmentId
                  }}
                  getValue={({ programmedStartTime }) => programmedStartTime.slice(0, 8)}
                  title="Próxima viagem"
                />
              </Col>

              <Col xl="3" lg="6" md="6" xs="12">
                <BoxInfo
                  queryOptions={{
                    query: TOTAL_VOLUME_QUERY,
                    variables: { shipmentId },
                    skip: !shipmentId
                  }}
                  getValue={({ serviceOrder }) => serviceOrder.serviceRequestOrder.quantity}
                  title="Volume total"
                />
              </Col>
              <MapsContainer>
                {
                  !!shipmentId && (
                    <Col xl="12" lg="12">
                      <ProgressBar shipmentId={shipmentId} />
                    </Col>
                  )
                }

                <Col xl={(noServiceOrders || !shipmentId) ? "12" : "6"} lg="12" md="12">
                  <GoogleMapsCard shipmentId={shipmentId} />
                </Col>
              </MapsContainer>

              {
                (!noServiceOrders && !!serviceOrderId) && (
                  <Col xl="6" lg="12" md="12">
                    <ServiceOrders
                      serviceOrderId={serviceOrderId}
                      noServiceOrdersHandler={this.onNoServiceOrders}
                    />
                  </Col>
                )
              }
            </Row>
          </Col>

          <Col xl="3" lg="12">
            <LoadInfo shipmentId={shipmentId} formulaCode={formulaCode} formulaId={formulaId} />
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <LoadsCard
              inProgress={false}
              changeSelectedShipment={this.changeSelectedShipment}
            />
          </Col>

          <Col lg="12">
            <LoadsCard
              inProgress
              changeSelectedShipment={this.changeSelectedShipment}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
