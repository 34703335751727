import styled from "styled-components";

export const TimeWithWeatherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const InfoContainer = styled.div`
  align-self: center;
  text-align: center;
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const VerticalLine = styled.div`
  border: 1px solid #E0E6FF;
  height: 56px;
`;

export const TemperatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 23px;
  padding-right: 23px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Hour = styled.h2`
  margin-top: 3px;
`;

export const Temperature = styled.h2`
  margin-left: 11px;
`;
