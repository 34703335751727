import React from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import styled from "styled-components";

const Quantity = styled.h2`
  font-size: 33px;
  color: #8798AD;
  text-align: right;
`;

const Identification = styled.p`
  font-weight: 500;
  color: #000;
  margin: 2px;
`;

const Text = styled.p`
  margin: 2px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0px 20px;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const Body = styled(CardBody)`
  position: relative;
  border: ${({ selected }) => (selected ? "2px solid #1D84C6" : "none")};
  border-radius: ${({ selected }) => (selected ? "10px" : "none")};
  padding: ${({ selected }) => (selected ? "8px 13px 8px 8px" : "10px 15px 10px 10px")} !important;
  cursor: pointer;

  &::before {
    top: 0px;
    left: 0px;
    position: absolute;
    content: "";
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: not-allowed;
    display: ${({ disabled }) => (disabled ? "block" : "none")};
  }
`;

const Icon = styled.i`
  color: #1D84C6;
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;

const ConciliateCard = ({
  quantity,
  formulaCode,
  startTime,
  identification,
  additionalInfo,
  selected,
  onClick,
  disabled,
}) => (
  <Card onClick={onClick}>
    <Body selected={selected} disabled={disabled}>
      <Content>
        <Left>
          <Identification>{identification}</Identification>
          <Text>{additionalInfo}</Text>
          <Text>{startTime}</Text>
        </Left>

        <Right>
          <Quantity>{quantity}</Quantity>
          <Text>{formulaCode}</Text>
        </Right>

        <Icon className="fas fa-check-circle" selected={selected} />
      </Content>
    </Body>
  </Card>
);

export default ConciliateCard;
