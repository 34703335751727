import React from "react";

const MapsContext = React.createContext({});

export const MapsProvider = MapsContext.Provider;

export const withMapsConsumer = (WrappedComponent) => {
  const Consumer = props => (
    <MapsContext.Consumer>
      { context => <WrappedComponent mapsContext={context} {...props} /> }
    </MapsContext.Consumer>
  );

  return Consumer;
};
