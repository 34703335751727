import styled from "styled-components";

export const BoxContainer = styled.div`
  text-align: center;

  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const InfoValue = styled.h2`
  margin-top: 3px;
`;
