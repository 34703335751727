import gql from "graphql-tag";

export const INVOICES_QUERY = gql`
  query invoices($serviceOrderId: Int) {
    invoices(serviceOrderId: $serviceOrderId) {
      sealNumber
      quantity
      startTime
    }
  }
`;
