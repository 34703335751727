import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from "react-router-dom";
import classnames from "classnames";
import styled from "styled-components";
import {
  Navbar as ReactstrapNavbar,
} from "reactstrap";
import ConcreteFormulaIdsContainer from "contexts/ConcreateFormulaIdsContext/ConcreteFormulaIdsContainer";
import InvoiceSubscriptionContainer from "contexts/InvoiceSubscriptionContext/InvoiceSubscriptionContainer";
import StockMaterialsNavbar from "components/Navbars/StockMaterials/StockMaterialsNavbar";
import FamiliesAndFormulasNavbar from "components/Navbars/FamiliesAndFormulas/FamiliesAndFormulasNavbar";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import ServiceOrdersNavbar from "components/Navbars/ServiceOrdersNavbar";
import ServiceOrdersFilterContainer from "contexts/ServiceOrdersFilterContainer";
import InvoiceResponseSubscription from "components/InvoiceResponse/InvoiceResponseSubscription";
import routes from "routes.js";
// import { withERP } from "utils/withERP";
import { withAuthentication } from "utils/withAuthentication";

const ServiceOrdersNavbarContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const DashboardName = styled.h2`
  color: #2E384D;
  font-family: "Rubik";
  font-size: 18px;
  font-weight: 500;
`;

const styles = {
  navbar: {
    justifyContent: "space-between",
    padding: "10px 15px",
    flexWrap: "wrap",
    boxShadow: "0 3px 6px rgba(135, 152, 173, 0.17)",
  },
};

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    };
    this.availableRoutes = routes(props.currentUser.role);
  }

  componentDidUpdate({ history, location }) {
    if (
      window.outerWidth < 993
      && history.location.pathname !== location.pathname
      && document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  getRoutes = () => this.availableRoutes.map((prop, key) => {
    if (prop.collapse) {
      return this.getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          name={prop.name}
        />
      );
    }
    return null;
  });

  getDashboardName = () => {
    const currentRoute = this.availableRoutes.find(route => `${route.layout}${route.path}` === this.props.history.location.pathname) || "";
    return currentRoute.name;
  }

  render() {
    const isServiceOrdersView = /\/admin\/service_orders(|\/)$/;
    const isStockMaterials = /\/admin\/stock_materials(|\/)$/;
    const isFamilyAndFormulas = /\/admin\/families_formulas(|\/)$/;
    const isInstrumentsPanel = /\/admin\/instruments_panel(|\/)$/;

    const { history } = this.props;

    return (
      <InvoiceSubscriptionContainer>
        <InvoiceResponseSubscription />
        <ServiceOrdersFilterContainer>
          <ConcreteFormulaIdsContainer>
            <div className="wrapper">
              <Sidebar
                {...this.props}
                routes={this.availableRoutes}
                bgColor={this.state.backgroundColor}
                activeColor={this.state.activeColor}
              />
              <div className="main-panel">
                <ReactstrapNavbar
                  className={classnames("navbar-absolute fixed-top", "bg-white")}
                  expand="lg"
                  style={styles.navbar}
                >
                  <div
                    className={classnames("navbar-toggle", {
                      toggled: this.state.sidebarOpen
                    })}
                  >
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.toggleSidebar}
                    >
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </button>
                  </div>
                  <DashboardName>
                    {this.getDashboardName()}
                  </DashboardName>
                  {
                    isServiceOrdersView.test(history.location.pathname)
                    && (
                      <ServiceOrdersNavbarContainer>
                        <ServiceOrdersNavbar />
                      </ServiceOrdersNavbarContainer>
                    )
                  }
                  {
                    isStockMaterials.test(history.location.pathname)
                    && (
                      <StockMaterialsNavbar />
                    )
                  }
                  {
                    isFamilyAndFormulas.test(history.location.pathname)
                    && (
                      <FamiliesAndFormulasNavbar />
                    )
                  }
                </ReactstrapNavbar>
                <Switch>{this.getRoutes(this.availableRoutes)}</Switch>

                {!isInstrumentsPanel.test(history.location.pathname) && <Footer fluid />}
              </div>
            </div>
          </ConcreteFormulaIdsContainer>
        </ServiceOrdersFilterContainer>
      </InvoiceSubscriptionContainer>
    );
  }
}

export default withAuthentication(Admin);
