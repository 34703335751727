/* eslint react/no-unused-state: 0 */

import React from "react";
import { compose } from "recompose";
import { getTrackerPosition } from "services/trackers";
import { withIterTokenFetch } from "utils/withIterTokenFetch";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { MapsProvider } from "./MapsContext";

const getEntitiesByUin = entities => (
  entities.reduce((entitiesAcum, nextEntity) => ({
    ...entitiesAcum,
    ...(nextEntity.uin && {
      [nextEntity.uin]: {
        ...nextEntity,
        name: nextEntity.name
      },
    })
  }), {})
);

const getElementManipulated = (arr1, arr2) => arr1
  .filter(x => !arr2.includes(x))
  .concat(arr2.filter(x => !arr1.includes(x)))[0];

class MapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equipment: null,
      setEquipmentPosition: this.setEquipmentPosition,
      equipmentLoading: false,
      entities: [],
      entitiesLoaded: false,
      addEntity: this.addEntity,
      refreshEntitiesPosition: this.refreshEntitiesPosition,
    };
  }

  componentDidMount() {
    const { iterToken, entities = [] } = this.props;
    if (!iterToken || entities.length <= 0) {
      this.setState({ entitiesLoaded: true, });
      return;
    }
    this.refresh(getEntitiesByUin(entities));
  }

  setEquipmentPosition = equipment => {
    if (!equipment || !equipment.uin || !this.props.iterToken) {
      this.setState({ equipment: null });
      return;
    }
    this.setState({ equipmentLoading: true });
    getTrackerPosition(equipment.uin, this.props.iterToken)
      .then(({ data }) => {
        this.setState({
          equipment: {
            ...equipment,
            id: data.uin,
            position: {
              lat: data.lat,
              lng: data.lng
            }
          },
        });
      })
      .catch(() => {
        this.props.snackbarContext.setNotificationTimeOut(
          Color.danger,
          `${equipment.code}: Erro ao obter posição.`
        );
      })
      .finally(() => {
        this.setState({ equipmentLoading: false, });
      });
  }

  refresh = (entitiesByUin, entitiesUpdated = []) => {
    const uinsArray = Object.keys(entitiesByUin);
    const currentUin = uinsArray[uinsArray.length - 1];

    getTrackerPosition(currentUin, this.props.iterToken)
      .then(({ data }) => {
        entitiesUpdated.push({
          ...entitiesByUin[data.uin],
          id: data.uin,
          position: {
            lat: data.lat,
            lng: data.lng
          }
        });
      })
      .catch(() => {
        this.props.snackbarContext.setNotificationTimeOut(
          Color.danger,
          `${entitiesByUin[currentUin].name}: Erro ao atualizar a posição.`
        );

        entitiesUpdated.push({
          id: currentUin,
          position: {},
          ...entitiesByUin[currentUin],
        });
      })
      .finally(() => setTimeout(() => {
        const nextUins = Object.keys(entitiesByUin).reduce((acum, nextUin) => ({
          ...acum,
          ...(nextUin !== currentUin && {
            [nextUin]: entitiesByUin[nextUin],
          })
        }), {});

        if (Object.keys(nextUins).length > 0) {
          return this.refresh(nextUins, entitiesUpdated);
        }

        return this.setState({
          entitiesLoaded: true,
          entities: entitiesUpdated
            .filter(({ position }) => position.lat && position.lng)
        });
      }, 2000));
  }

  refreshEntitiesPosition = () => {
    if (!this.props.iterToken || this.state.entities.length === 0) {
      return;
    }
    this.refresh(getEntitiesByUin(this.state.entities));
  }

  addEntity = newEntities => {
    if (!this.props.iterToken) {
      return;
    }

    if (newEntities.length > this.state.entities.length) {
      const entityToAdd = getElementManipulated(newEntities, this.state.entities);
      getTrackerPosition(entityToAdd.uin, this.props.iterToken)
        .then(({ data }) => {
          this.setState(({ entities }) => ({
            entities: entities.concat({
              ...entityToAdd,
              id: entityToAdd.uin,
              type: entityToAdd.type,
              position: {
                lat: data.lat,
                lng: data.lng
              }
            })
          }));
        })
        .catch(() => {
          this.props.snackbarContext.setNotificationTimeOut(
            Color.danger,
            `${entityToAdd.name}: Erro ao adicionar.`
          );
        });

      return;
    }

    const entityToRemove = getElementManipulated(this.state.entities, newEntities);
    this.setState(({ entities }) => ({
      entities: entities.filter(entity => entity.uin !== entityToRemove.uin)
    }));
  }

  render() {
    return (
      <MapsProvider value={this.state}>
        {this.props.children}
      </MapsProvider>
    );
  }
}

export default compose(
  withIterTokenFetch,
  withSnackbarConsumer,
)(MapsContainer);
