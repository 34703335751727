import React from "react";
import { graphql, compose } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { httpClient } from "settings/apolloClient";

import pauseIcon from "assets/img/pause-button.svg";
import continueIcon from "assets/img/continue-button.svg";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext";
import translateErrorMessage from "utils/translateErrorMessage";

import PAUSE_PROCESS_MUTATION from "./graphql/pauseProcessMutation";
import CURRENT_AUTOMATION_STATUS_QUERY from "./graphql/currentAutomationStatusQuery";
import ActionButton from "./ActionButton";

const PauseButton = ({
  hasShipment,
  pauseShipment,
  snackbarContext,
}) => {
  const { data } = useQuery(CURRENT_AUTOMATION_STATUS_QUERY, {
    client: httpClient,
    pollInterval: hasShipment ? 1000 : undefined,
  });

  const paused = data && data.currentAutomationStatus && data.currentAutomationStatus.status === "PAUSED";

  const {
    icon,
    label,
    action,
    message,
  } = paused
    ? {
      label: "Continuar",
      action: "continuar",
      message: "O carregamento está em progresso...",
      icon: continueIcon,
    }
    : {
      label: "Pausar",
      action: "pausar",
      message: "O carregamento foi pausado",
      icon: pauseIcon,
    };

  const onConfirm = () => {
    pauseShipment({
      variables: {
        status: !paused,
      },
      refetchQueries: [
        { query: CURRENT_AUTOMATION_STATUS_QUERY },
      ]
    })
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, message);
      })
      .catch((error) => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  };

  return (
    <ActionButton
      icon={icon}
      label={label}
      onConfirm={onConfirm}
      classes="pause"
      action={action}
      active={paused}
      disabled={!hasShipment}
    />
  );
};

export default compose(
  withSnackbarConsumer,
  graphql(PAUSE_PROCESS_MUTATION, { name: "pauseShipment" }),
)(PauseButton);
