import gql from "graphql-tag";

export default gql`
query ServiceOrder($id: Int!) {
  serviceOrder(id: $id) {
    id
    contract {
      id
      latitude
      longitude
    }
    equipmentShipments {
      id
      status
      equipment {
        id
        uin
        code
      }
    }
  }
}
`;
