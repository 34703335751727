import React from "react";

export const withPolling = injectedProps => Component => {
  class Wrapper extends React.Component {
    componentDidMount() {
      const params = injectedProps(this.props);

      params.pollingFunction();
      this.pollingInterval = setInterval(
        () => {
          params.pollingFunction();
        },
        params.timeInterval
      );
    }

    componentWillUnmount() {
      clearInterval(this.pollingInterval);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return Wrapper;
};
