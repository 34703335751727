/*global google*/

import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import { withQuery } from "containers/withQuery";
import { API_KEY_QUERY } from "./googleMapsQuery";

class MarkeredGoogleMaps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      directions: null,
    };
  }

  componentDidMount() {
    this.setDirections(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setDirections(nextProps);
  }

  setDirections = (selectedProps) => {
    const { contractPosition, selectedTravel } = selectedProps;
    if (selectedTravel) {
      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route({
        origin: new google.maps.LatLng(selectedTravel.position.lat, selectedTravel.position.lng),
        destination: new google.maps.LatLng(contractPosition.lat, contractPosition.lng),
        travelMode: google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        }
      });
    }
  }

  render() {
    const {
      defaultZoom,
      contractPosition,
      travels,
    } = this.props;
    const { directions } = this.state;

    return (
      <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={{
          lat: -23.552025,
          lng: -46.634949
        }}
      >
        {directions && <DirectionsRenderer directions={directions} />}
        {contractPosition && (
          <Marker
            position={contractPosition}
            title="Contrato"
            clickable
          />
        )}
        {travels.map((travel) => (
          <Marker
            key={travel.id}
            position={travel.position}
            title={travel.name}
            clickable
          />
        ))}
      </GoogleMap>
    );
  }
}

MarkeredGoogleMaps.defaultProps = {
  defaultZoom: 20,
  travels: [],
  selectedTravel: null
};



export default compose(
  withQuery({
    query: API_KEY_QUERY,
  }),
  withProps(({ googleApiKey }) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleApiKey}`,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "482px" }} />,
    mapElement: <div style={{ minHeight: "100%" }} />,
  })),
  withScriptjs,
  withGoogleMap
)(MarkeredGoogleMaps);
