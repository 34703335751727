/* eslint no-restricted-globals: 0 */
// For isNaN usage
import React, { Component } from "react";
import { Modal, Button } from "reactstrap";
import { withQuery } from "containers/withQuery";
import { compose, graphql } from "react-apollo";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { withConcreteFormulaIdsConsumer } from "contexts/ConcreateFormulaIdsContext/ConcreteFormulaIdsContext";
import translateErrorMessage from "utils/translateErrorMessage";
import CostBox from "./CostBox.jsx";
import { CONCRETE_FORMULA_CREATE_QUERY } from "./concreteFormulaQueries.js";
import {
  InfoContainer,
  CostBoxContainer,
  TableContainer,
} from "./ConcreteTracesModalStyle.jsx";
import { CREATE_CONCRETE_FORMULA_MUTATION } from "./concreteFormulaMutations.js";
import MaterialsTable from "./MaterialsTable.jsx";
import ConcreteFormulaHeader from "./ConcreteFormulaHeader.jsx";
import ConcreteFormulaBody from "./ConcreteFormulaBody.jsx";
import { CREATE_REQUIRED_FIELDS, MATERIAL_REQUIRED_FIELDS, isBlank } from "./requiredFields";
import { calculateMortarFactor, calculateWcFactor } from "./calculations.js";
import getConcreteFamiliesOptions from "./getConcreteFamiliesOptions";

class ConcreteFormulaCreate extends Component {
  state = {
    isEditable: false,
    shouldUpdate: false,
    isActive: false,
    materials: [],
    margin: 0,
    use: "",
    code: "",
    concreteFamily: {},
    fck: "",
    fcj: "",
    fctmk: "",
    consumption: 0,
    mortarFactor: "",
    wcRatio: "",
    aggressivenessIndex: "",
    incorporatedAir: "",
    observations: "",
  }

  formatToR$ByVolume = value => `R$ ${value}/m³`;

  onChangeData = (key, name) => this.setState({ [key]: name, shouldUpdate: false });

  onChangeActive = e => this.setState(({ isActive: e.state.value, }))

  onChangeMaterials = materials => {
    const mortarFactor = calculateMortarFactor(materials);
    const wcRatio = calculateWcFactor(materials);

    this.setState({
      materials,
      shouldUpdate: false,
      mortarFactor,
      wcRatio,
    });
  }

  isValid = (object, requiredFields) => {
    const { snackbarContext } = this.props;

    const missingFields = requiredFields.filter(field => {
      const value = object[field.key];

      if (field.select) {
        return !value || isBlank(value[field.select]);
      }

      return isBlank(value);
    });

    if (missingFields.length === 0) return true;

    const message = missingFields.length === 1
      ? `${missingFields[0].label} é obrigatório`
      : `${missingFields.map(({ label }) => label).join(", ")} são obrigatórios`;

    snackbarContext.setNotificationTimeOut(Color.danger, message);
    return false;
  };

  onSaveClick = () => {
    const {
      isActive,
      use,
      code,
      fck,
      fcj,
      fctmk,
      concreteFamily,
      aggressivenessIndex,
      incorporatedAir,
      observations,
      materials,
      margin,
    } = this.state;

    if (!this.isValid(this.state, CREATE_REQUIRED_FIELDS)) {
      return;
    }

    if (materials.length === 0) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "É necessário informar pelo menos 1 material.");
      return;
    }

    const invalidMaterial = materials.find(material => !this.isValid(material, MATERIAL_REQUIRED_FIELDS));

    if (invalidMaterial) {
      return;
    }

    const { refetch } = this.props.concreteFormulaIdsContext;

    this.props.createConcreteFormula({
      variables: {
        params: {
          aggressivenessIndex: parseFloat(aggressivenessIndex),
          code,
          concreteFamilyId: concreteFamily.value,
          fcj: parseInt(fcj, 10),
          fck: parseInt(fck, 10),
          fctmk: parseFloat(fctmk),
          formulaMaterials: materials.map(material => ({
            inventoryItemId: material.name.value,
            proportion: parseFloat(material.proportion),
            order: material.id,
            weight: parseFloat(material.weight.label),
            specificWeight: parseFloat(material.specificWeight.label),
            supplierId: parseInt(material.supplier.value, 10)
          })),
          incorporatedAir: parseFloat(incorporatedAir),
          isActive,
          margin,
          observations,
          use,
        },
      },
    })
      .then(() => refetch && refetch())
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Traço criado com sucesso.");
        this.props.closeModalHandler();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const {
      isActive,
      use,
      code,
      concreteFamily,
      fck,
      fcj,
      fctmk,
      consumption,
      mortarFactor,
      wcRatio,
      aggressivenessIndex,
      incorporatedAir,
      observations,
    } = this.state;

    const concreteFamiliesOptions = getConcreteFamiliesOptions(this.props.concreteFamilies);

    return (
      <Modal
        isOpen={this.props.modalOpen}
        toggle={this.props.closeModalHandler}
        size="lg"
        style={{ maxWidth: "1600px", width: "80%" }}
      >
        <ConcreteFormulaHeader
          onChangeActive={this.onChangeActive}
          closeModalHandler={this.props.closeModalHandler}
          isActive={isActive}
        />

        <ConcreteFormulaBody
          isEditable
          use={use}
          concreteFamily={concreteFamily}
          concreteFamiliesOptions={concreteFamiliesOptions}
          fck={fck}
          fcj={fcj}
          fctmk={fctmk}
          consumption={consumption}
          mortarFactor={mortarFactor}
          wcRatio={wcRatio}
          aggressivenessIndex={aggressivenessIndex}
          incorporatedAir={incorporatedAir}
          observations={observations}
          onChangeData={this.onChangeData}
          code={code}
        />

        <TableContainer className="modal-body" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          <MaterialsTable
            materials={[]}
            onChangeMaterials={this.onChangeMaterials}
            inventoryItems={this.props.inventoryItems}
            isEditable
            shouldUpdate={this.state.shouldUpdate}
          />
          <InfoContainer>
            <CostBoxContainer>
              <CostBox
                title="Custo total do traço"
                value="-"
              />
              <CostBox
                isEditable
                keyValue="margin"
                onChange={this.onChangeData}
                title="Margem"
                value={this.state.margin}
              />
              <CostBox
                title="Preço"
                value="-"
              />
            </CostBoxContainer>
          </InfoContainer>
        </TableContainer>
        <div className="modal-footer">
          <Button
            style={{ width: "100%", padding: "17px 16px", margin: 0 }}
            color="info"
            data-dismiss="modal"
            type="button"
            onClick={this.onSaveClick}
          >
            Criar Traço
          </Button>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withSnackbarConsumer,
  withConcreteFormulaIdsConsumer,
  graphql(CREATE_CONCRETE_FORMULA_MUTATION, { name: "createConcreteFormula", }),
  withQuery(() => ({
    noLoader: true,
    query: CONCRETE_FORMULA_CREATE_QUERY,
    errorPolicy: "ignore",
  })),
)(ConcreteFormulaCreate);
