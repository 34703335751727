import React from "react";
import randIdGenerator from "utils/idGenerator.js";
import styled from "styled-components";

const circleProps = {
  concluida: {
    color: "#00B630",
    icon: "fa fa-truck"
  },
  caminho: {
    color: "#FFAA00",
    icon: "fa fa-map-marker"
  },
  carregando: {
    color: "#1D84C6",
    icon: "fa fa-sync"
  },
  aguardando: {
    color: "#FB404B",
    icon: "fa fa-truck"
  }
};

const StatusIcon = ({ type, onClick }) => {
  const randId = randIdGenerator();
  const theme = {
    backgroundColor: `${circleProps[type].color}`
  };
  const CircularIcon = styled.div`
    background-color: ${theme.backgroundColor};
    width: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 46px;
    color: #fff;
    border-radius: 46px;
    cursor: pointer;
  `;

  return (
    <CircularIcon
      id={`clickable-${randId}`}
      onClick={onClick}
    >
      <i className={circleProps[type].icon} />
    </CircularIcon>
  );
};

export default StatusIcon;
