import React from "react";
import "./TeamInfo.css";
import { withQuery } from "containers/withQuery";
import DropdownOperator from "./DropdownOperator";
import { OPERATORS_QUERY } from "../../views/Dashboard/LoadInfo/loadInfoQueries";
import { OperatorContainer, AvatarsContainer } from "./teamInfoComponents";

class TeamInfo extends React.Component {
  operatorsRemaining = this.props.operators;

  constructor(props) {
    super(props);

    this.initialState = {
      operatorsToShow: this.props.operators,
      operatorsSelected: [],
      teamSelected: [],
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    const { setClearCallback } = this.props;
    if (setClearCallback) {
      setClearCallback(this.onClear);
    }
  }

  onClear = () => this.setState(this.initialState);

  onAdd = (key, operator, onCompleted) => {
    this.operatorsRemaining = this.operatorsRemaining
      .filter(operatorRemaining => operatorRemaining.id !== operator.id);

    this.setState(({ [key]: items }) => ({
      [key]: items.concat(operator),
      operatorsToShow: this.operatorsRemaining
    }), onCompleted);
  }

  onEdit = (key, newOperator, previousOperator, onCompleted) => {
    this.operatorsRemaining = this.operatorsRemaining
      .filter(operatorRemaining => operatorRemaining.id !== newOperator.id)
      .concat(previousOperator);

    this.setState(({ [key]: items }) => ({
      [key]: items
        .filter(operatorRemaining => operatorRemaining.id !== previousOperator.id)
        .concat(newOperator),
      operatorsToShow: this.operatorsRemaining
    }), onCompleted);
  }

  onDelete = (key, operator, onCompleted) => {
    this.operatorsRemaining = this.operatorsRemaining.concat(operator);

    this.setState(({ [key]: items }) => ({
      [key]: items.filter(operatorRemaining => operatorRemaining.id !== operator.id),
      operatorsToShow: this.operatorsRemaining
    }), onCompleted);
  }

  onChangeText = e => {
    const { value } = e.target ? e.target : e;
    this.setState(() => ({
      operatorsToShow: this.operatorsRemaining.filter(
        operatorRemaining => operatorRemaining.name
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    }));
  };

  render () {
    return (
      <React.Fragment>
        <OperatorContainer>
          <h3>Operador</h3>
          <AvatarsContainer>
            <DropdownOperator
              type="operator"
              operatorsSelected={this.state.operatorsSelected}
              operatorsToShow={this.state.operatorsToShow}
              showAddButton={this.state.operatorsSelected.length < 1}
              onChangeText={this.onChangeText}
              onAdd={(operator) => this.onAdd("operatorsSelected", operator, () => {
                this.props.onOperatorChange("operatorsSelected", this.state.operatorsSelected);
              })}
              onEdit={(newOperator, previousOperator) => {
                this.onEdit("operatorsSelected", newOperator, previousOperator, () => {
                  this.props.onOperatorChange("operatorsSelected", this.state.operatorsSelected);
                });
              }}
              onDelete={(operator) => this.onDelete("operatorsSelected", operator, () => {
                this.props.onOperatorChange("operatorsSelected", this.state.operatorsSelected);
              })}
            />
          </AvatarsContainer>
        </OperatorContainer>
        <OperatorContainer>
          <h3>Equipe</h3>
          <AvatarsContainer>
            <DropdownOperator
              type="team"
              operatorsSelected={this.state.teamSelected}
              operatorsToShow={this.state.operatorsToShow}
              showAddButton={this.state.teamSelected.length < 3}
              onChangeText={this.onChangeText}
              onAdd={(operator) => this.onAdd("teamSelected", operator, () => {
                this.props.onOperatorChange("teamSelected", this.state.teamSelected);
              })}
              onEdit={(newOperator, previousOperator) => {
                this.onEdit("teamSelected", newOperator, previousOperator, () => {
                  this.props.onOperatorChange("teamSelected", this.state.teamSelected);
                });
              }}
              onDelete={(operator) => this.onDelete("teamSelected", operator, () => {
                this.props.onOperatorChange("teamSelected", this.state.teamSelected);
              })}
            />
          </AvatarsContainer>
        </OperatorContainer>
      </React.Fragment>
    );
  }
}

export default withQuery({
  query: OPERATORS_QUERY
})(TeamInfo);
