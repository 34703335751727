import gql from "graphql-tag";

export const SHIPMENTS_QUERY = gql`
  query equipmentShipments($inProgress: Boolean!, $date: Date) {
    equipmentShipments(inProgress: $inProgress, date: $date) {
      id
      equipmentRequestOrder {
        id
        quantity
      }
      invoice {
        id
        quantity
        startTime
        travelStartTime
      }
      programmedStartTime
      equipment {
        id
        code
      }
      serviceOrder {
        id
        code
        serviceRequestOrder {
          id
          serviceDetail {
            id
            name
          }
          service {
            id
            code
          }
        }
      }
    }
  }
`;
