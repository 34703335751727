import { Button } from "reactstrap";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 28px !important;
  margin-bottom: 16px !important;
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const InfoContainer = styled.div`
  text-align: left;
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 38px;
  margin-bottom: 16px;
`;

export const EmptyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 38px;
  margin-bottom: 16px;
  min-height: 300px;
`;

export const InfoText = styled.p`
  margin-top: 7px;
`;

export const EquipmentContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 38px;
  margin-bottom: 16px;
`;

export const TruckContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const EquipmentCode = styled.p`
  margin-top: 7px;
`;
