import gql from "graphql-tag";

export default gql`
mutation FinishShipment ($equipmentShipmentId: Int!){
  finishShipment(equipmentShipmentId: $equipmentShipmentId) {
    id
    status
  }
}
`;
