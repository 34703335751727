import gql from "graphql-tag";

export const LOAD_QUERY = gql`
  query equipmentShipment($id: Int!) {
    equipmentShipment(id: $id) {
      id
      invoice {
        id
        quantity
        startTime
        travelStartTime
      }
      equipmentRequestOrder {
        id
        quantity
      }
      programmedStartTime
      client {
        id
        name
      }
      contract {
        id
        code
        identification
        address
        neighborhood
        number
        city
        state
        cep
        complement
        placeComments
      }
      operators {
        id
        name
        photo
      }
      equipment {
        id
        code
      }
      serviceOrder {
        id
        code
        serviceRequestOrder {
          id
          service {
            id
            code
          }
          serviceDetail {
            id
            name
          }
        }
      }
    }
  }
`;

export const OPERATORS_QUERY = gql`
  query {
    operators {
      id
      name
      photo
    }
  }
`;
