import React from "react";
import { Card } from "reactstrap";
import { compose } from "react-apollo";
import { withQuery } from "containers/withQuery";
import CountShipmentsQuery from "graphql/queries/get/countShipmentsQuery";
import "./OSCardInfo.css";
import StatusIcon from "components/StatusIcon/StatusIcon.jsx";

const OSCardInfo = ({ title, status, countShipments }) => (
  <Card>
    <div className="os-card-container">
      <p>{title}</p>
      <div className="os-card-number-info">
        <StatusIcon type={status} />
        <h2>{countShipments}</h2>
      </div>
    </div>
  </Card>
);

export default compose(
  withQuery(({ serviceOrderId, status }) => ({
    query: CountShipmentsQuery,
    variables: {
      serviceOrderId,
      status,
    }
  }))
)(OSCardInfo);
