import React from "react";
import { Row, Col } from "reactstrap";

import ResumeCard from "./ResumeCard/ResumeCard";
import OSCardInfo from "./OSCardInfo/OSCardInfo";
import ServiceOrderTable from "./ServiceOrderTable/ServiceOrderTable";
import ServiceOrderMap from "./ServiceOrderMap/ServiceOrderMap";

class ServiceOrderDetail extends React.Component {
  render() {
    const serviceOrderId = parseInt(this.props.match.params.id, 10);
    return (
      <div className="content">
        <Row>
          <Col xl="7" lg="12">
            <ResumeCard serviceOrderId={serviceOrderId} />
          </Col>
          <Col xl="5" lg="12">
            <Row>
              <Col xl="6" lg="6" md="6">
                <OSCardInfo
                  title="Viagens Concluídas"
                  serviceOrderId={serviceOrderId}
                  status="FINISHED"
                />
              </Col>
              <Col xl="6" lg="6" md="6">
                <OSCardInfo
                  title="Viagens à Caminho"
                  serviceOrderId={serviceOrderId}
                  status="IN_PROGRESS"
                />
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6">
                <OSCardInfo
                  title="Viagens Carregando"
                  serviceOrderId={serviceOrderId}
                  status="LOADING"
                />
              </Col>
              <Col xl="6" lg="6" md="6">
                <OSCardInfo
                  title="Viagens Aguardando"
                  serviceOrderId={serviceOrderId}
                  status="WAITING"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12">
            <ServiceOrderTable serviceOrderId={serviceOrderId} />
          </Col>
        </Row>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12">
            <ServiceOrderMap serviceOrderId={serviceOrderId} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ServiceOrderDetail;
