import styled from "styled-components";
import { Line } from "rc-progress";

export const Container = styled.div`
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin-top: 10px;
`;

export const ProgressLine = styled(Line)`
  width: 100%;
  height: 10px;
`;
