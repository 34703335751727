import React from "react";
import {
  Card,
} from "reactstrap";

import { withQuery } from "containers/withQuery";
import { BoxContainer, InfoValue } from "./boxInfoComponents";

const BoxInfo = ({
  title,
  equipmentShipment,
  getValue,
}) => (
  <Card className="card-stats">
    <BoxContainer>
      <p>{title}</p>

      <InfoValue>
        {(equipmentShipment && (getValue(equipmentShipment))) || "-"}
      </InfoValue>
    </BoxContainer>
  </Card>
);

export default withQuery(({ queryOptions }) => queryOptions)(BoxInfo);
