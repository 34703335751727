import gql from "graphql-tag";

export default gql`
query ResumeCardQuery($id: Int!) {
  serviceOrder(id: $id) {
    id
    shipmentsProgress
    client {
      id
      name
    }
    contract {
      id
      clientId
      address
      complement
      neighborhood
      city
      state
      cep
    }
  }
}
`;
