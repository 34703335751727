import React from "react";
import { Card, Row, Col } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { withQuery } from "containers/withQuery";
import styled from "styled-components";
import RESUME_CARD_QUERY from "graphql/queries/get/resumeCardQuery";
import { chartData, chartOptions } from "settings/doughnutConfig";
import "./ResumeCard.css";

const ShadowCard = styled(Card)`
  box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.45);
  &:hover {
    box-shadow: 9px 9px 17px -4px rgba(0, 0, 0, 0.45);
  }
`;

const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const ResumeCard = ({ ...props }) => {
  const {
    address,
    complement,
    neighborhood,
    city,
    state,
    cep
  } = props.serviceOrder.contract;
  const { shipmentsProgress } = props.serviceOrder;

  return (
    <ShadowCard className="card-stats">
      <div className="resume-card-container">
        <Row>
          <CenteredCol xs="12" md="3">
            <div className="doughnut-container">
              <Doughnut
                data={chartData(shipmentsProgress)}
                options={chartOptions(shipmentsProgress)}
                className="ct-chart ct-perfect-fourth"
                height={140}
                width={140}
              />
            </div>
          </CenteredCol>

          <Col xs="12" md="9">
            <div className="resume-card-1">
              <h3>Cliente</h3>
              <p>{props.serviceOrder.client.name}</p>
            </div>

            <div className="resume-card-1">
              <h3>Endereço</h3>
              <p>{`${address}, ${complement} - ${neighborhood}, ${city} (${state}), Brasil, CEP: ${cep}`}</p>
            </div>
          </Col>
        </Row>
      </div>
    </ShadowCard>
  );
};

export default withQuery(({ serviceOrderId }) => ({
  query: RESUME_CARD_QUERY,
  variables: {
    id: serviceOrderId
  }
}))(ResumeCard);
