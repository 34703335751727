import React from "react";
import { withRouter } from "react-router-dom";
import {
  Card, CardBody, Row, CardFooter, Col
} from "reactstrap";
import StatusIcon from "components/StatusIcon/StatusIcon.jsx";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { chartData, chartOptions } from "settings/doughnutConfig";

const CardButton = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

const StyledStatusIcon = styled.div`
  margin-right: 5px;
`;

const ShadowCard = styled(Card)`
  box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.45);
  &:hover {
    box-shadow: 9px 9px 17px -4px rgba(0, 0, 0, 0.45);
    background-color: #f5f5f5;
  }
`;

const FullWidthRow = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardBodyCentered = styled(CardBody)`
  display: flex;
  justify-content: center;
  min-height: 170px;
`;

const AddressInfo = styled.div`
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
  color: #606060;
`;

const CustomCardFooter = styled(CardFooter)`
  display: flex;
  margin-top: -10px;
  flex-wrap: wrap;
`;

const HorizontalRow = styled.div`
  border-bottom: 2px dotted #C3CBD8;
  padding-bottom: 5px;
  width: 100%;
  margin:  7px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const OsTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 510;
  align-items: flex-start;
`;

const ServiceOrder = ({ history, ...props }) => {
  const {
    address,
    number,
    neighborhood,
    city,
    state,
    cep
  } = props.osData.contract;
  return (
    <CardButton
      type="button"
      onClick={() => history.push(`/admin/service_orders/${props.osData.id}`)}
    >
      <ShadowCard>
        <CardBodyCentered>
          <FullWidthRow>
            <HorizontalRow>
              <Row>
                <Col sm={7}>
                  <OsTitle>
                    {`OS ${props.osData.code}`}
                  </OsTitle>
                  <AddressInfo>
                    {
                      `${address}, ${number} ${neighborhood}, ${city} (${state}) - Brasil CEP: ${cep}`
                    }
                  </AddressInfo>
                </Col>
                <Col sm={5}>
                  <Doughnut
                    data={chartData(props.osData.shipmentsProgress)}
                    options={chartOptions(props.osData.shipmentsProgress)}
                    className="ct-chart ct-perfect-fourth"
                    height={20}
                    width={20}
                  />
                </Col>
              </Row>
            </HorizontalRow>
          </FullWidthRow>
        </CardBodyCentered>
        <CustomCardFooter>
          {props.osData.equipmentShipments.map(({ id, status }) => (
            <StyledStatusIcon
              key={id}
            >
              <StatusIcon
                type={status}
              />
            </StyledStatusIcon>
          ))}
        </CustomCardFooter>
      </ShadowCard>
    </CardButton>
  );
};


export default withRouter(ServiceOrder);
