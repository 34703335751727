import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  min-height: 150px;
`;

export const Title = styled.h3`
  padding-left: 31px;
  padding-right: 32px;
  padding-top: 22px;
  margin-bottom: 19px;
`;

export const Content = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 28px;
  margin-top: 22px;
  margin-bottom: 20px;
`;

export const NextLoadRow = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const NextLoadItem = styled.div`
  flex: 1;
  min-width: 250px;
  max-width: 250px;
  margin: 4px 8px 4px 8px;
  cursor: pointer;
`;
