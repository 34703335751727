import React from "react";
import randIdGenerator from "utils/idGenerator.js";
import styled from "styled-components";

const circleProps = {
  FINISHED: {
    color: "#00B630",
    icon: "fa fa-truck"
  },
  IN_PROGRESS: {
    color: "#FFAA00",
    icon: "fa fa-map-marker"
  },
  LOADING: {
    color: "#1D84C6",
    icon: "fa fa-sync"
  },
  WAITING: {
    color: "#FB404B",
    icon: "fa fa-truck"
  }
};

const StatusIcon = ({ type }) => {
  const randId = randIdGenerator();
  const theme = {
    backgroundColor: `${circleProps[type].color}`
  };
  const CircularIcon = styled.div`
    background-color: ${theme.backgroundColor};
    width: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 46px;
    color: #fff;
    border-radius: 46px;
  `;

  return (
    <React.Fragment>
      <CircularIcon
        id={`uncontroled${randId}`}
      >
        <i className={circleProps[type].icon} />
      </CircularIcon>
    </React.Fragment>
  );
};

export default StatusIcon;
