/* eslint no-restricted-globals: 0 */
// For isNaN usage
import styled from "styled-components";

export const InfoContainer = styled.div`
  text-align: left;
  padding-left: 11px;
  padding-right: 23px;
  margin-top: 20px;
  margin-bottom: 6px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FamilyContainer = styled.div`
   padding: 0;
   border-bottom: 1px solid rgb(221, 221, 221);
`;

export const MaterialsContainer = styled.div`
   padding: 0;
   border-bottom: 1px solid rgb(221, 221, 221);
   padding-bottom: 30px;
`;

export const TableContainer = styled.div`
   padding: 0;
   border-bottom: 1px solid rgb(221, 221, 221);
   padding-bottom: 30px;
`;

export const InfoText = styled.p`
  margin-top: 7px;
  font-size: 14px;
  padding: 10px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
`;

export const CostBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
`;

export const TableActionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const materialHeaders = inventoryItems => ({
  id: {
    label: "#",
  },
  name: {
    label: "Nome",
    isEditable: true,
    component: "select",
    options: inventoryItems.map(({
      id, name, materialType, unit
    }) => ({
      value: id, label: name, materialType, unit
    }))
  },
  supplier: {
    label: "Fornecedor",
    isEditable: true,
    component: "select",
    options: inventoryItems.reduce((acum, inventoryItem) => ({
      ...acum,
      [inventoryItem.id]: inventoryItem.suppliers.map(({
        id, name
      }) => ({
        value: id,
        label: name
      }))
    }), {})
  },
  materialType: {
    label: "Tipo"
  },
  specificWeight: {
    label: "Massa Específica",
    isEditable: true,
    component: "text",
    formatter: (value, unit) => `${parseFloat(value).toFixed(3)} ${unit}`,
    isValid: value => !isNaN(value) && value >= 0
  },
  weight: {
    label: "Peso",
    isEditable: true,
    component: "text",
    formatter: (value, unit) => `${parseFloat(value).toFixed(1)} ${unit}`,
    isValid: value => !isNaN(value) && value >= 0
  },
  quantity: {
    label: "Volume",
  },
  proportion: {
    label: "Proporção",
    isEditable: true,
    component: "text",
    formatter: (value) => `${(parseFloat(value) * 100).toFixed(0)}%`,
    isValid: value => !isNaN(value) && value >= 0
  },
});

export const materialTypes = {
  SAND: "Areia",
  GRAVEL: "Brita",
  CEMENT: "Cimento",
  ADDITIVE: "Aditivo",
  WATER: "Água",
  ADDITION: "Adição"
};
