import React from "react";
import useDidMount from "@rooks/use-did-mount";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import styled from "styled-components";

const MIN_VALUE = 10;
const MAX_VALUE = 100;

const WaterSliderContainer = styled.div`
  background-color: #F6F8FF;
  width: 59.5%;

  padding: 25px 35px 25px 35px;
`;

const Text = styled.p`
  color: #283745;
  font-family: "Rubik";
  font-size: 15px;
  font-weight: 500;
  text-align: center;

  margin-top: 8px;
`;

const SliderInfoContainer = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WaterSlider = ({
  waterValue,
  onChangeSlider,
}) => {
  useDidMount(() => {
    onChangeSlider(waterValue);
  });

  return (
    <WaterSliderContainer>
      <SliderInfoContainer>
        <p>
          {`${MIN_VALUE}%`}
        </p>
        <p>
          {`${MAX_VALUE}%`}
        </p>
      </SliderInfoContainer>

      <Slider
        min={MIN_VALUE}
        max={MAX_VALUE}
        step={0.01}
        value={waterValue}
        onChange={number => onChangeSlider(number)}
        style={{ marginTop: "10px", color: "#1D84C6" }}
      />
      <Text>na automação</Text>
    </WaterSliderContainer>
  );
}

export default WaterSlider;
