import React from "react";
import {
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import AddImg from "assets/img/add.svg";
import ReactSVG from "react-svg";
import OperatorsList from "components/OperatorsList/OperatorsList";
import DropdownMenu from "./DropdownMenu";


class DropdownOperator extends React.Component {
  state = {
    addDropdownOpen: false,
    editDropdownOpen: false,
  }

  toggleDropdownAdd = () => {
    this.setState(({ addDropdownOpen }) => ({
      addDropdownOpen: !addDropdownOpen,
    }), () => this.props.onChangeText({ value: "" }));
  }

  toggleDropdownEdit = () => {
    this.setState(({ editDropdownOpen }) => ({
      editDropdownOpen: !editDropdownOpen,
    }), () => this.props.onChangeText({ value: "" }));
  }

  render() {
    const { currentTeamOperator } = this.state;
    const { operatorsSelected, operatorsToShow, type } = this.props;
    return (
      <React.Fragment>
        {/* Edit Dropdown */}
        <Dropdown isOpen={this.state.editDropdownOpen} toggle={this.toggleDropdownEdit}>
          <DropdownToggle tag="div" color="info">
            <OperatorsList
              type={type}
              operators={operatorsSelected}
              onClick={(operator) => {
                this.setState({ currentTeamOperator: operator });
              }}
              onDelete={this.props.onDelete}
            />
          </DropdownToggle>
          <DropdownMenu
            operators={operatorsToShow}
            onClick={(operator) => this.props.onEdit(operator, currentTeamOperator)}
            onChangeText={this.props.onChangeText}
          />
        </Dropdown>

        {/* Add Dropdown */}
        <Dropdown isOpen={this.state.addDropdownOpen} toggle={this.toggleDropdownAdd}>
          <DropdownToggle tag="div" style={{ width: "37px" }} color="info">
            {this.props.showAddButton && (
              <ReactSVG src={AddImg} svgClassName="crew-image" svgStyle={{ borderRadius: "50%" }} />
            )}
          </DropdownToggle>
          <DropdownMenu
            operators={operatorsToShow}
            onClick={this.props.onAdd}
            onChangeText={this.props.onChangeText}
          />
        </Dropdown>
      </React.Fragment>

    );
  }
}

export default DropdownOperator;
