import React from "react";
import { format } from "date-fns";
import { withQuery } from "containers/withQuery";
import LoadCard from "components/LoadCard/LoadCard";
import {
  Container,
  Title,
  Content,
  NextLoadRow,
  NextLoadItem
} from "./loadsCardComponents";
import { SHIPMENTS_QUERY } from "./loadsCardQueries";

const LoadsCardContent = ({ equipmentShipments, onChange }) => (
  <Content>
    <NextLoadRow>
      {equipmentShipments.map(shipment => (
        <NextLoadItem
          key={shipment.id}
          onClick={() => onChange(shipment)}
        >
          <LoadCard {...shipment} />
        </NextLoadItem>
      ))}
    </NextLoadRow>
  </Content>
);

const LoadsCardContainer = withQuery(({ inProgress }) => ({
  noLoader: true,
  query: SHIPMENTS_QUERY,
  variables: {
    inProgress,
    date: format(new Date(), "YYYY-MM-DD"),
  },
  fetchPolicy: "no-cache",
}))(LoadsCardContent);

class LoadsCard extends React.PureComponent {
  render() {
    return (
      <Container>
        <Title>
          {this.props.inProgress ? "Cargas em Progresso" : "Próximas Cargas"}
        </Title>
        <LoadsCardContainer
          inProgress={this.props.inProgress}
          onChange={this.props.changeSelectedShipment}
        />
      </Container>
    );
  }
}

export default LoadsCard;
