import gql from "graphql-tag";

export default gql`
query ServiceOrder($id: Int!) {
  serviceOrder(id: $id) {
    id
    equipmentShipments {
      id
      status
      equipment {
        id
        code
      }
      operators {
        id
        name
        photo
      }
      programmedStartTime
      invoice {
        id
        quantity
        startTime
        endTime
        recentInvoiceRequest {
          id
          status
          reason
          enotasId
        }
      }
      equipmentRequestOrder {
        id
        quantity
      }
    }
  }
}
`;
