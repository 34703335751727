import React from "react";
import { Table, Card, Button } from "reactstrap";
import { getIn } from "utils/nesting";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";
import { format } from "date-fns";
import update from "immutability-helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { httpClient } from "settings/apolloClient";

import SERVICE_ORDER_EQUIPMENT_SHIPMENTS_QUERY from "graphql/queries/list/serviceOrderEquipmentShipmentsQuery";
import COUNT_SHIPMENT_QUERY from "graphql/queries/get/countShipmentsQuery";
import RESUME_CARD_QUERY from "graphql/queries/get/resumeCardQuery";

import FINISH_SHIPMENT from "graphql/mutations/finishShipmentMutation";
import CREATE_INVOICE from "graphql/mutations/createInvoiceRequest";
import CANCEL_INVOICE from "graphql/mutations/cancelInvoiceRequest";
import OperatorsList from "components/OperatorsList/OperatorsList";
import { ErrorParagraph, InfoParagraph } from "components/StyledComponents";
import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { convertArrayToObject } from "utils/convertArrayToObject";
import { withInvoiceSubscriptionConsumer } from "contexts/InvoiceSubscriptionContext/InvoiceSubscriptionContext";

import InvoiceButton from "./InvoiceButton";
import { FETCH_PDF_LINK } from "./ServiceOrderQueries";

import "./ServiceOrderTable.css";

const AlertModal = withReactContent(Swal);

const AUTHORIZED = "AUTHORIZED";
const CANCELED = "CANCELED";
const ON_HOLD = "ON_HOLD";
const ERROR = "ERROR";
const NOT_EMITTED = "NOT_EMITTED";

const headers = [
  "Quantidade",
  "Equipamento",
  "Equipe",
  "Programado",
  "Início",
  "Finalizada",
  "Status Viagem",
  "Status DANFE",
  "DANFE",
];

const statusMap = {
  FINISHED: "FINALIZADO",
  IN_PROGRESS: "EM PROGRESSO",
  LOADING: "CARREGANDO",
  WAITING: "EM ESPERA",
};

const danfeStatusMap = {
  AUTHORIZED: "AUTORIZADA",
  CANCELED: "CANCELADA",
  ON_HOLD: "EM ESPERA",
  ERROR: "ERRO DETECTADO",
  NOT_EMITTED: "NÃO EMITIDA",
};

const getDanfeStatus = invoice => {
  if (!invoice || !invoice.recentInvoiceRequest || !invoice.recentInvoiceRequest.enotasId) {
    return NOT_EMITTED;
  }

  const request = invoice.recentInvoiceRequest;
  if (!request.status) {
    return ON_HOLD;
  }

  const gatewayStatus = {
    Autorizada: AUTHORIZED,
    Cancelada: CANCELED,
    Negada: ERROR,
    CancelamentoNegado: AUTHORIZED,
  };

  return gatewayStatus[request.status];
};

class ServiceOrderTable extends React.Component {
  constructor(props) {
    super(props);

    const { serviceOrder: { equipmentShipments } } = this.props;
    this.state = {
      loadingFinishEquipmentShipment: false,
      shipments: convertArrayToObject(equipmentShipments,
        shipment => shipment.id,
        () => ({ loading: false }))
    };

    this.danfeButtonsMap = {
      AUTHORIZED: [
        {
          color: "#00B630",
          icon: "fa fa-download",
          callback: this.onDownload,
          key: "authorized_download",
          tooltip: "Fazer download",
        },
        {
          color: "#FB404B",
          icon: "fa fa-times",
          callback: this.showCancelModal,
          key: "authorized_cancel",
          tooltip: "Cancelar",
        },
      ],
      CANCELED: [
        {
          color: "#1D84C6",
          icon: "fa fa-plus",
          callback: this.onSend,
          key: "canceled",
          tooltip: "Emitir nova nota fiscal",
        },
      ],
      ON_HOLD: [
        {
          color: "#B1C2D8",
          icon: "fa fa-hourglass",
          callback: null,
          key: "on_hold",
          tooltip: "Aguardando...",
        },
      ],
      ERROR: [
        {
          color: "#FFAA00",
          icon: "fa fa-exclamation",
          callback: this.onCheckError,
          key: "error",
          tooltip: "Verificar erro",
        },
      ],
      NOT_EMITTED: [
        {
          color: "#1D84C6",
          icon: "fa fa-plus",
          callback: this.onSend,
          key: "not_emitted",
          tooltip: "Enviar nota fiscal",
        },
      ]
    };
  }

  componentDidMount() {
    const {
      refetch,
      serviceOrderId,
      invoiceSubscriptionContext: { setAfterSubscriptionData },
    } = this.props;

    setAfterSubscriptionData(() => refetch({
      variables: {
        serviceOrderId
      }
    }));
  }

  componentWillUnmount() {
    const { invoiceSubscriptionContext: { clearAfterSubscriptionData } } = this.props;

    clearAfterSubscriptionData();
  }

  onCheckError = ({ equipmentShipment, key }) => () => {
    const reason = getIn(["invoice", "recentInvoiceRequest", "reason"], equipmentShipment);

    AlertModal.fire({
      title: "Nota fiscal negada!",
      html: (
        <InfoParagraph>
          {`MOTIVO: ${reason}`}
        </InfoParagraph>
      ),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Emitir novamente",
      cancelButtonText: "Voltar",
      reverseButtons: true,
    })
      .then(result => {
        if (result.value) {
          this.onSend({ equipmentShipment, key })();
        }
      });
  };

  showCancelModal = ({ equipmentShipment, key }) => () => {
    AlertModal.fire({
      title: "Você tem certeza que deseja cancelar esta nota fiscal?",
      html: (
        <ErrorParagraph>
          Esta alteração não pode ser desfeita!
        </ErrorParagraph>
      ),
      type: "error",
      showCancelButton: true,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
      reverseButtons: true,
    })
      .then(result => {
        if (result.value) {
          this.onCancel({ equipmentShipment, key });
        }
      });
  };

  onCancel = ({ equipmentShipment, key }) => {
    const { cancelInvoice, serviceOrderId } = this.props;
    const equipmentShipmentId = equipmentShipment.id;
    const invoiceRequestId = getIn(["invoice", "recentInvoiceRequest", "id"], equipmentShipment);
    this.startLoading(equipmentShipmentId, key);

    cancelInvoice({
      variables: {
        invoiceRequestId,
      },
      refetchQueries: [
        {
          query: SERVICE_ORDER_EQUIPMENT_SHIPMENTS_QUERY,
          variables: { id: serviceOrderId },
        }
      ]
    })
      .then(() => {
        const { snackbarContext } = this.props;
        snackbarContext.setNotificationTimeOut(Color.info, "Cancelamento solicitado.");
      })
      .catch(error => {
        const { snackbarContext } = this.props;
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      })
      .finally(() => this.stopLoading(equipmentShipmentId));
  };

  onSend = ({ equipmentShipment, key }) => () => {
    const { createInvoice, serviceOrderId } = this.props;
    const equipmentShipmentId = equipmentShipment.id;
    this.startLoading(equipmentShipmentId, key);

    createInvoice({
      variables: {
        equipmentShipmentId,
      },
      refetchQueries: [
        {
          query: SERVICE_ORDER_EQUIPMENT_SHIPMENTS_QUERY,
          variables: { id: serviceOrderId },
        }
      ]
    })
      .then(({ data: { createInvoiceRequest: message } }) => {
        const { snackbarContext } = this.props;
        snackbarContext.setNotificationTimeOut(Color.info, message);
      })
      .catch(error => {
        const { snackbarContext } = this.props;
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      })
      .finally(() => this.stopLoading(equipmentShipmentId));
  };

  onDownload = ({ equipmentShipment, key }) => () => {
    const equipmentShipmentId = equipmentShipment.id;
    const invoiceRequestId = getIn(["invoice", "recentInvoiceRequest", "id"], equipmentShipment);
    this.startLoading(equipmentShipmentId, key);

    httpClient.query({
      query: FETCH_PDF_LINK,
      variables: { invoiceRequestId }
    })
      .then(({ data: { invoicePdfLink } }) => {
        window.open(invoicePdfLink, "_blank");
      })
      .catch(error => {
        const { snackbarContext } = this.props;
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      })
      .finally(() => this.stopLoading(equipmentShipmentId));
  };

  canFinish = (status) => status === "IN_PROGRESS" && !this.state.loadingFinishEquipmentShipment;

  finishEquipmentShipment = (equipmentShipment) => () => {
    const { snackbarContext } = this.props;

    if (this.canFinish(equipmentShipment.status)) {
      const { finishShipment, serviceOrderId } = this.props;

      this.setState({ loadingFinishEquipmentShipment: true });
      finishShipment({
        variables: {
          equipmentShipmentId: equipmentShipment.id,
        },
        refetchQueries: [
          {
            query: SERVICE_ORDER_EQUIPMENT_SHIPMENTS_QUERY,
            variables: { id: serviceOrderId },
          },
          {
            query: COUNT_SHIPMENT_QUERY,
            variables: { serviceOrderId, status: "FINISHED" },
          },
          {
            query: COUNT_SHIPMENT_QUERY,
            variables: { serviceOrderId, status: "IN_PROGRESS" },
          },
          {
            query: COUNT_SHIPMENT_QUERY,
            variables: { serviceOrderId, status: "LOADING" },
          },
          {
            query: COUNT_SHIPMENT_QUERY,
            variables: { serviceOrderId, status: "WAITING" },
          },
          {
            query: RESUME_CARD_QUERY,
            variables: { id: serviceOrderId },
          }
        ]
      })
        .catch(error => {
          snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
        })
        .finally(() => this.setState({ loadingFinishEquipmentShipment: false }));
    } else {
      snackbarContext.setNotificationTimeOut(Color.danger, "Não é possível finalizar uma carga que não está à caminho");
    }
  }

  startLoading = (shipment, key) => this.setState(state => update(state, {
    shipments: {
      [shipment]: {
        loading: {
          $set: key
        }
      }
    }
  }));

  stopLoading = (shipment) => this.setState(state => update(state, {
    shipments: {
      [shipment]: {
        loading: {
          $set: null
        }
      }
    }
  }));

  render() {
    const { serviceOrder: { equipmentShipments } } = this.props;
    const { shipments } = this.state;

    return (
      <Card>
        <Table responsive>
          <thead>
            <tr>
              {headers.map(header => (
                <th className="text-center" key={header}>
                  <h3>{header}</h3>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {equipmentShipments.map(equipmentShipment => {
              const quantity = getIn(["invoice", "quantity"], equipmentShipment);
              const startTime = getIn(["invoice", "startTime"], equipmentShipment);
              const endTime = getIn(["invoice", "endTime"], equipmentShipment);

              const status = getDanfeStatus(equipmentShipment.invoice);
              const { loading } = shipments[equipmentShipment.id];

              return (
                <tr key={equipmentShipment.id}>
                  <td className="text-center">
                    <p>{startTime ? parseFloat(quantity).toFixed(1) : `(${parseFloat(equipmentShipment.equipmentRequestOrder.quantity).toFixed(1)})`}</p>
                  </td>
                  <td className="text-center">
                    <p>{equipmentShipment.equipment.code}</p>
                  </td>
                  <td className="text-center table-service-order-team">
                    {equipmentShipment.operators.length > 0
                      ? <OperatorsList type={equipmentShipment.id} operators={equipmentShipment.operators} />
                      : "-"
                    }
                  </td>
                  <td className="text-center">
                    <p>{equipmentShipment.programmedStartTime}</p>
                  </td>
                  <td className="text-center">
                    <p>{startTime ? format(new Date(startTime), "DD/MM/YYYY HH:mm") : "-"}</p>
                  </td>
                  <td className="text-center">
                    <p>{endTime ? format(new Date(endTime), "DD/MM/YYYY HH:mm") : "-"}</p>
                  </td>
                  <td className="text-center">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.finishEquipmentShipment(equipmentShipment)}
                      disabled={!this.canFinish(equipmentShipment.status)}
                      style={{ margin: 0 }}
                    >
                      {statusMap[equipmentShipment.status]}
                    </Button>
                  </td>
                  <td className="text-center">
                    <p>{danfeStatusMap[status]}</p>
                  </td>
                  <td>
                    <div className="d-flex flex-row justify-content-center">
                      {
                        this.danfeButtonsMap[status].map(({
                          color,
                          key,
                          icon,
                          tooltip,
                          callback
                        }) => (
                          <InvoiceButton
                            key={key}
                            loading={loading === key}
                            icon={icon}
                            color={color}
                            tooltip={tooltip}
                            buttonId={`${key}${equipmentShipment.id}`}
                            onClick={callback && callback({ equipmentShipment, key })}
                          />
                        ))
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default compose(
  withQuery(({ serviceOrderId }) => ({
    query: SERVICE_ORDER_EQUIPMENT_SHIPMENTS_QUERY,
    variables: { id: serviceOrderId },
  })),
  graphql(FINISH_SHIPMENT, { name: "finishShipment", }),
  graphql(CREATE_INVOICE, { name: "createInvoice", }),
  graphql(CANCEL_INVOICE, { name: "cancelInvoice", }),
  withInvoiceSubscriptionConsumer,
  withSnackbarConsumer,
)(ServiceOrderTable);
