import gql from "graphql-tag";

export default gql`
query {
  actuators {
    id
    name
    status
    type
  }
}
`;
